.edit-text {
  overflow-wrap: break-word;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 26.04px !important;
  color: rgba(27, 62, 97, 1) !important;

  .MuiInputBase-input {
    overflow-wrap: break-word;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: rgba(27, 62, 97, 1);
  }
}

.edit-board-field-name {
  margin-left: 8px !important;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.25px;
  text-align: left;
  color: #212b36;
  text-transform: capitalize;
}
