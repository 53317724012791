.noWorklog {
  height: 350px;
  width: 735px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    opacity: 60%;
    color: rgba(0, 0, 0, 1);
    margin-top: 20px;
    text-align: center;
    max-width: 383px;
  }
  .noWorklog-add-worklog-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    button {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 18.23px;
      text-align: center;

      height: 40px;
      // width: 145px;
      padding: 6px 10px;
      background-color: #1b3e61;
    }
  }
}

@media screen and (max-width: 992px) {
  .noWorklog {
    height: 100%;
    width: 100%;
    margin-top: 20px;
  }
}
