.add-comment{
    margin-left: 5px;
    margin-top: 30px;
    // width: 735px;
}

.add-comment-avtar{
    height: 34px;
    width: 34px;
}
.add-comment-field{
    width: 695px;
    border: 1px solid rgba(145, 158, 171, 0.25);
    padding: 14px 20px;
    margin-left: 12px;
    border-radius: 4px;
    color: rgba(30, 31, 32, 0.25);
}

.comment-textEditor{
    margin-left: 10px;
    width: 695px;

    .ql-toolbar.ql-snow {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 40px !important;
        padding: 5px !important;
        border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .ql-container{
        height: 100px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .text-editor-btn-div{ 
        margin-top: 16px;
        .text-editor-btn1 {
            height: 36px !important;
            width: auto !important;
            border-radius: 4px;
            &:disabled {
              background-color: rgba(
                0,
                0,
                0,
                0.12
              ) !important; /* Light grey background for disabled */
              cursor: not-allowed;
            }
            min-width: 90px !important;
            text-transform: capitalize;
            height: 40px;

            .text-editor-btn-content{ 
                font-weight: 700; 
                text-transform: capitalize;
                font-size: 16px;
            }
          }
    
        .text-editor-btn2{
            margin-left: 10px !important;
            height: 36px !important;
            width: auto !important;
            min-width: 90px !important;
            color: black !important;
            opacity: 0.5 !important;
            border: 1px solid rgba(13, 20, 97, 0.5) !important;
    
            .text-editor-btn-content{ 
                font-weight: 700; 
                text-transform: capitalize;
                font-size: 16px;
            }
        }
    }

}

