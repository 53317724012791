.attachment-img {
  height: 86px;
}
.attachment-detail {
  height: 35px;
  padding: 5px;
}
.attachment-name {
  font-family: DM Sans;
  height: 13px;
  font-size: 10px;
  font-weight: 500;
  line-height: 13.02px;
  // text-align: left;
  color: rgba(33, 43, 54, 1);
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attachment-img {
  padding: 0px;
}
.attachment-date {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.02px;
  text-align: left;
  color: rgba(33, 43, 54, 0.6);
}
.attachment-img-data {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 86px;
  width: 150px;
}
.attachment-view {
  position: absolute;
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin-right: 53px;
  margin-top: 4px;
  cursor: pointer;
}
.attachment-download {
  position: absolute;
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin-right: 28px;
  margin-top: 4px;
  cursor: pointer;
}
.attachment-delete {
  position: absolute;
  // background-color: white;
  // height: 20px;
  // width: 20px;
  // border-radius: 4px;
  margin-right: 4px;
  margin-top: 4px;
  cursor: pointer;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
}
.attachment-para {
  margin-top: 24px;
}
.attachment-img-type {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .mobile-attachment-name {
//   font-size: 12px;
//   min-width: 135px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

@media screen and (max-width: 992px) {
  .attachment-detail {
    height: 50px;
    padding: 5px;
    width: 170px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(145, 158, 171, 0.16);
    justify-content: center;
    cursor: pointer;
  }
  // .attachment-name {
  //   font-size: 12px;
  // }
  .mobile-attachment-name {
    font-size: 12px;
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .attachment-delete {
    margin-top: 0px;
  }
  .attachment-download {
    margin-top: 0px;
  }
  .icons {
    margin-top: 16px;
  }
  .attachment-date {
    margin-bottom: 10px;
  }
}
