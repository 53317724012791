.new-add-file {
  padding: 0; /* Remove any padding */
  border: none; /* Remove border */
  background-color: transparent; /* Make background transparent */
  width: fit-content; /* Button width adjusts to the content width */
  /* Change cursor to default to indicate no interaction */
  margin-top: 10px !important;
  height: 24px !important;
  width: 24px !important;
  min-width: 0px !important;
}

.new-add-file img {
  display: block; /* Remove any extra space below the image */
}

.new-add-file:hover {
  background-color: transparent; /* Disable hover effect */
  cursor: pointer; /* Disable pointer cursor on hover */
}

@media screen and (max-width: 992px) {
  .new-add-file {
    height: 15px !important;
  }
}
