.work-hour {
  width: 100%;
}
.work-hour-box {
  margin-top: 15px;
}
.work-hour-title {
  font-family: DM Sans;
  font-weight: 500;
  opacity: 0.6;
}
.text-editor {
  height: 150px;
}
.work-discription-title {
  font-family: DM Sans;
  font-weight: 500;
  opacity: 0.6;
  margin-top: 30px;
}
.work-date-title {
  font-family: DM Sans;
  font-weight: 500;
  opacity: 0.6;
  margin-top: 80px;
}
.work-date-input {
  width: 100%;
}
.worklog-btn {
  display: flex;
  // margin-left: 315px;
  justify-content: end;
  margin-top: 15px;
}
.btn-content {
  font-family: DM Sans;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: capitalize;
}

.worklog-btn-cancel {
  margin-left: 15px !important;
  height: 42px !important;
  width: 130px !important;
  color: black !important;
  opacity: 0.5 !important;
  border: 1px solid rgba(13, 20, 97, 0.5) !important;
}
.worklog-btn-save {
  height: 42px;
  width: 130px;
  margin-left: -35px;
  border-radius: 4px;
  background-color: #1b3e61 !important;
}
.add-worlog-title-dialog {
  color: #1b3e61;
  font-weight: bolder;
  font-family: DM Sans;
}
.number-error-message {
  height: 10px;
}
.number-error-message-field {
  color: red;
}
.error-message {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}
.datepicker {
  margin-top: -15px;
}

@media screen and (max-width: 992px) {
  .add-worlog-title-dialog {
    font-size: 16px;
  }
  .mobile-addWorklog-title {
    padding: 8px 14px !important;
  }
  .work-hour-title {
    font-size: 14px;
  }
  .work-discription-title {
    margin-top: 10px;
    font-size: 14px;
  }
  .work-hour {
    .MuiInputBase-input {
      padding: 8px 10px;
    }
  }
  .work-date-title {
    font-size: 14px;
  }
  .worklog-btn-save {
    height: 40px;
    width: 90px;
  }
  .worklog-btn-cancel {
    height: 40px !important;
    width: 90px !important;
  }
}
