.text-editor-box {
  margin-top: 5px;
  height: 406px;
  .ql-toolbar.ql-snow {
    border: 1px solid rgba(145, 158, 171, 0.25);
    padding: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ql-container.ql-snow {
    border: 1px solid rgba(145, 158, 171, 0.25);
  }
}

.react-quill-description {
  .ql-tooltip {
    left: 0px !important;
  }
}

#editor {
  height: 160px;
  border: 1px solid rgba(145, 158, 171, 0.25);
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.text-editor-btn-div {
  margin-top: 34px;
}

.text-editor-btn1 {
  height: 42px !important;
  width: auto !important;
  background-color: #1b3e61 !important;
  // background-color: #70777d;
  min-width: 110px !important;
  box-shadow: none !important;
}

.text-editor-btn2 {
  margin-left: 10px !important;
  height: 42px !important;
  width: 110px !important;
  color: black !important;
  opacity: 0.5 !important;
  border: 1px solid rgba(13, 20, 97, 0.5) !important;
}

.text-editor-btn-content {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 16px;
}
.description-box {
  overflow-y: auto;
  word-wrap: break-word;
  height: 160px;
  margin-top: 5px;
  // padding: 12px 0px 0px 0px;
  // gap: 10px;
  border-radius: 4px;
  // border: 2px solid rgba(0, 0, 0, 0.185);
  border: 1px solid rgba(145, 158, 171, 0.25);
}

#editor1 {
  border: none;
  box-shadow: none;
  .ql-editor {
    ul,
    ol {
      padding-left: 0px;
    }
  }

  &.ql-blank::before {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.23px;
    margin-left: 5px;
    color: #919eab;
    content: attr(
      data-placeholder
    ); // Use the placeholder attribute for empty content
  }
}

// .ql-editor ul, ol {
//     padding-left: 0px;
//   }

.Add-desc-message2 {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  margin-left: 5px;
  color: #919eab;
}
.ql-container.ql-disabled .ql-editor {
  user-select: text !important;
  -webkit-user-select: text !important; // For Safari
  cursor: text !important;
}

@media screen and (max-width: 992px) {
  .text-editor-btn1 {
    height: 40px !important;
    min-width: 90px !important;
  }
  .text-editor-btn2 {
    height: 40px !important;
    width: 90px !important;
  }
}
