.avatar-container {
  position: relative;
  display: inline-block;

  &:hover {
    .icon-overlay {
      opacity: 1;
      // pointer-events: none;
      // transform: scale(1.2); /* Increase size on hover */
      // img {
      //   filter: brightness(0) invert(1); /* Make the pencil black on hover */
      // }
    }

    .user-avatar {
      // filter: blur(1px) brightness(0.5) invert(0.5); /* Apply blur effect on hover */
      background: black;
    }
    .user-avatar img {
      opacity: 0.8;
    }
  }

  .icon-overlay {
    position: absolute;
    top: 60%;
    left: 35%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
    /* Ensure the icon doesn't block clicks to the input */
  }

  .user-avatar {
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
