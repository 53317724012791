@import "../../../styles/css/variables";
body {
  .heading-worklog-tab {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.25px;
    color: #212b36;

    .total-work {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .download-worklogs {
    }
  }
  .hours-header {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
  .worklog-filter {
    // margin-top: 18px;
    // width: 1120px;
    // height: 72px;
    // gap: 0px;
    // border-radius: 8px;
    // background-color: #f2f4f6;

    // border: 1px solid #919eab1f;
    // padding: 0 32px;

    .filter-by {
      font-family: DM Sans;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #1b3e61;
    }
  }
  .worklog-item {
    margin-top: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    .board-anquor {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 18.23px;
      color: #1b3e61;
    }
    .board-anquor:hover {
      text-decoration: underline;
    }
    .worklog-title {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 18.23px;
      color: #212b36;
    }
    .hours {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 18.23px;
      color: #212b36;
    }
    .worklog-worklog {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 12px;
      color: #212b36;
    }
    .worklog-status {
      gap: 0px;
      border-radius: 4px;
      background-color: var(--success-8, #22c55e14);
      border: 1px solid var(--success-16, #22c55e29);
      color: var(--primary-main, #00a76f);
      width: fit-content;
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 15.62px;
      padding: 2px 10px;
      text-align: right;
      margin-bottom: 10px;
    }
    .worklog-date-time {
      font-family: DM Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 16.93px;
      justify-content: end;
      color: #212b3699;
      display: flex;
      align-items: center;

      .late-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
      }
    }
  }
  .right-details {
    float: right;
  }
  .both-hours {
    font-family: DM Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    color: #212b36;
  }
  .worklog-scroll {
    overflow-y: auto;
    /* max-height: 1000px; */
    /* min-height: 100px; */
    /* height: 50vh; */
    /* max-height: 100%; */
    /* padding-top: 14px; */
    height: calc(100vh - 250px);
  }
  .custom-tab {
    color: #000 !important;
    font-weight: normal;
    font-size: 14px;
    text-transform: none;
    padding: 7px 24px !important;
    height: 32px;
    background-color: #fff;
    border: 1px solid #1b3e6114;
    border-radius: 113px;
    margin-left: 8px;
    min-height: 32px;
    line-height: 1;
  }

  .custom-tab-active {
    color: #fff !important;
    font-weight: bold;
    font-size: 14px;
    text-transform: none;
    background-color: #1b3e61;
    border-radius: 20px;
    padding: 7px 24px !important;
    min-height: 32px;
    margin-left: 8px;
    line-height: 1;
  }
  .worklog_board_name {
    font-size: 18px;
    color: #1b3e61;
    font-weight: 700;
    text-transform: capitalize;
    // flex-grow: 1;
  }
  .worklog_board_cont {
    border: 1px solid #919eab33;
    border-radius: 6px;
    margin-top: 8px;
  }
  .worklog_board_header {
    display: flex;
    padding: 13px 20px 13px 20px;
    justify-content: space-between;
    background-color: #f2f4f6;
    p {
      font-size: 18px;
      color: #1b3e61;
    }
    .team-status-title {
      font-size: 13px;
    }
    .checkmark {
      width: 14px;
      height: 14px;
      // background-color: #ccc; /* Default background color */
      border-radius: 4px;
      position: relative;
      margin-right: 8px;
      border: 1px solid #919eab;
    }
    .checkmark::after {
      left: 5px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .worklog_board_accordian_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .total_project_hours {
    text-align: right;
    width: 150px;
    font-size: 18px;
    color: #1b3e61;
    font-weight: 700;
    margin-right: 63px;
  }
  .total-text {
    font-size: 16px;
    font-weight: 700;
  }
  .total-hours {
  }
}

@media screen and (max-width: 992px) {
  body {
    .worklog-scroll {
      height: calc(100vh - 145px);
      background-color: lightgray;
      padding-left: 10px;
      padding-right: 10px;
    }

    .worklog-item {
      margin-left: 0px !important;
      .board-anquor {
        font-size: 13px;
        font-weight: 600;
      }

      .worklog-title {
        font-size: 13px;
        font-weight: 600;
      }

      .hours {
        font-size: 13px;
        font-weight: 600;
      }

      .worklog-status {
        margin-bottom: 0px;
        margin-top: 0px !important;
      }
    }

    .worklog_board_name {
      font-size: 14px;
      font-weight: 600;
    }
    .total_project_hours {
      width: 70px;
      font-size: 14px;
    }
  }
}
