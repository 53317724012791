.datepicker .MuiStack-root {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .mobile-datepicker {
    .MuiInputBase-input {
      padding: 8px 10px;
    }
  }
}
