.forgotpassword-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  min-height: 100vh;
}

.forgotpassword-innerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  justify-content: center;
  padding: 40px 15px;
}
.submitForm-box {
  margin-top: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 30px 0px rgba(27, 62, 97, 0.06);
  background: #ffffff;
  padding: 40px !important;
}
.submit-button {
  padding: 13px 16px !important;
  margin-top: 14px !important;
}
