.edit-comment-textEditor{
    // margin-left: 12px;
    // margin-top: -20px;
    // margin-left: -5px;
    width: 695px;

    .ql-toolbar.ql-snow {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 40px !important;
        padding: 5px !important;
        border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .ql-container{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .text-editor-btn-div{ 
        margin-top: 16px;
    
        .text-editor-btn1{
            height: 36px !important;
            width: auto !important;
            background-color: #1B3E61 !important;
            min-width: 90px !important;
            box-shadow: none !important;
    
                .text-editor-btn-content{ 
                    font-weight: 700; 
                    text-transform: capitalize;
                    font-size: 16px;
                }
        }
        .text-editor-btn2{
            margin-left: 10px !important;
            height: 36px !important;
            width: auto !important;
            min-width: 90px !important;
            color: black !important;
            opacity: 0.5 !important;
            border: 1px solid rgba(13, 20, 97, 0.5) !important;
    
            .text-editor-btn-content{ 
                font-weight: 700; 
                text-transform: capitalize;
                font-size: 16px;
            }
        }
    }

}
