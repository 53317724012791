body {
  .card-column {
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    .coloumn-header {
      border-radius: 12px;
      background: rgba(242, 244, 246, 1);
      padding: 12px 16px;
      margin-bottom: 8px;
      h4 {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: rgb(33, 43, 54);
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
    .coloumn-body {
      border-radius: 12px;
      padding: 8px;
      width: 250px;
    }
    .coloumn-body-1 {
      height: 150px;
      border-radius: 12px;
      padding: 8px;
      width: 250px;
    }
    .coloumn-body-2 {
      height: 300px;
      border-radius: 12px;
      padding: 8px;
      width: 250px;
    }
    .coloumn-body-3 {
      height: 450px;
      border-radius: 12px;
      padding: 8px;
      width: 250px;
    }
  }
}
.delete-card {
  cursor: pointer;
}
.delete-card-button {
  height: 30px;
  width: 30px;
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
}
.avatar-container {
  display: flex;
  position: relative;
}
.checkMark-position {
  position: absolute;
  top: -2px;
  right: -3px;
  z-index: 1;
}
.mt-28 {
  padding-top: 28px !important;
}
.parent-title-id {
  position: relative;
  display: inline-flex;
  padding-right: 20px;
}
.parent-title-id:after {
  content: "";
  position: absolute;
  right: 0;
  background: url("../../../../styles/svg/parent-task-arrow\ .svg");
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 4px;
}
.subtasks.subtasks-uline {
  display: block;
  padding-top: 1px;
}
