.card-status-dropdown-parent{
   border: none !important;
   .card-status-dropdown{
        background-color: rgba(253, 116, 1, 0.08) !important;
        border: 1px solid rgba(253, 116, 1, 0.25) !important;
        color: rgba(253, 116, 1, 1) !important;
        background-image: url('../../styles/svg/arrow-down.svg');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 20px;
        .MuiSelect-select{
            padding-top: 3px;
            padding-bottom: 2px;
            font-size: 14px;
            font-weight: 500;
            padding-left: 6px;
        }
        .MuiSvgIcon-root{
            display: none;
        }
        fieldset{ 
            border: 0;
            top: 0;
        }
    }
}

.project-status-button{
    background: none;
    border: none;
    width: 100%;
    color: rgb(250, 116, 6);
    font-family: DM Sans;
font-size: 14px;
font-weight: 500;
line-height: 22px;
// margin-top: 3px;
// text-align: left;
}
.project-status{
    //  width: 109px;
    // display: flex;
    //   justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    height: 28px;
    // top: 15px;
    // left: 204px;
    // margin-top: 4px;
    // border-radius: 4px 0px 0px 0px;
    border-radius: 4px;
    // border: 1px 0px 0px 0px;
    // opacity: 0px;
    background: rgba(253, 116, 1, 0.08);
    border: 1px solid rgba(253, 116, 1, 0.25)
    }