.create-epic-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 5px;
  }

  button {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    text-align: center;
    width: 95%;
    height: 40px;
    margin: 15px 5px 0px 5px;
    // width: 145px;
    padding: 6px 10px;
    color: rgba(255, 255, 255, 1);
  }
}

.listItem-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  // text-align: left;
  color: rgba(33, 43, 54, 1);
}
// .epic-list .MuiMenu-paper {
//     width: 222px;
//     ul {
//       li {
//         padding: 8px 8px;
//         font-size: 14px;
//         &.active-assign {
//           font-weight: 500;
//         }
//       }
//     }
//   }
// .epic-list ui{
//     max-height: 40vh;
//     overflow-y: auto;
//     padding: 8px;
// }
// .checkbox{
//     color: rgba(255, 255, 255, 1);
//     border: 1px solid rgba(145, 158, 171,1);
//     border-radius: 4px;
//     height: 20px;
//     width: 20px;
//     margin: 0px 10px 0px 13px;
// }
// .checkbox-cheked{
//     background: rgba(0, 184, 217, 1);
//     border: 1px solid rgba(0, 184, 217, 1);
//     border-radius: 4px;
//     height: 20px;
//     width: 20px;
//     margin: 0px 10px 0px 13px;
// }
.epic-list {
  // text-align: left;

  p {
    font-family: DM Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.62px;
    // text-align: left;
    color: rgba(33, 43, 54, 0.6);
  }
}
.epic-checkbox {
  svg {
    // padding: 0px 9px 0px 0px !important;
    border: 1px solid rgba(145, 158, 171, 1) !important;
    width: 20px !important;
    height: 20px !important;

    border-radius: 4px !important;

    path {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid rgba(145, 158, 171, 1) !important;
    }
  }
}

.custom-checkbox input {
  display: none;
}

// / Create a custom checkbox /
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

// / Create the checkmark/indicator (hidden by default) /
.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  // background-color: #ccc; / Default background color /
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
  border: 1px solid #919eab;
}

// / When the checkbox is checked, show the indicator /
.custom-checkbox input:checked + .checkmark {
  background-color: #00b8d9;
}

// / Add the checkmark/indicator (hidden by default) /
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

// / Show the checkmark when checked /
.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

// / Style the checkmark/indicator /
.custom-checkbox .checkmark::after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.epic-name {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  // text-align: left;
  color: rgba(33, 43, 54, 1);
}

.epic-id {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.62px;
  // text-align: left;
  color: rgba(33, 43, 54, 0.6);
  margin-top: 5px;
}
.epic-name-id-div {
  margin-top: 10px;
  margin-left: 5px;
}
.epic-name-id-div-noMilestone {
  margin-left: 5px;
}
.major-div {
  margin-left: 10px;
  width: 100%;
}
.major-div-noMilestone {
  padding: 0px 18px;
  width: 100%;
}
.major-div-select-All {
  // margin-left: 10px;
  width: 100%;
}
.epic-name-id-div-select-All {
  margin-left: 5px;
  margin-top: 0px;
}

.create-epic-textarea {
  border-radius: 6px;
  border: 1.5px solid rgba(253, 116, 1, 1);
  padding: 10px;
  padding-left: 35px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #212b36;
  margin-top: 10px;

  // padding-bottom: 32px;
}
.create-epic-textarea:focus {
  border-radius: 6px;
  outline: none !important;
  border: 1.5px solid rgba(253, 116, 1, 1) !important;
}

.create-epic-field-image {
  margin-bottom: 40px;
  height: 20px;
  width: 20px;
}

.completed-epics {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

@media screen and (max-width: 992px) {
  .create-epic-btn {
    button {
      margin: 0px !important;
      width: 100%;
      margin-right: 0px;
      margin-top: 5px !important;
    }
  }

  .epic-name {
    font-size: 13px;
    font-weight: 400;
  }

  .epic-id {
    font-family: DM Sans;
    font-size: 11px;
    font-weight: 600;
    margin-top: 5px;
  }

  .create-epic-btn {
    display: flex;
    justify-content: end;
    align-items: end;
    button {
      font-family: DM Sans;
      font-size: 13px;
      font-weight: 400;
      width: auto;
      height: 28px;
      padding: 3px 16px;
      margin: auto;
      margin-right: 0px;
      margin-top: 5px !important;
    }
  }

  .custom-checkbox .checkmark::after {
    left: 5px !important;
    width: 2px !important;
    height: 6px !important;
  }
}
