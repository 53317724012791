@import "variables";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  margin: 0;
  padding: 0;
}
body * {
  font-family: "DM Sans" !important;
}
body {
  color: #212b36;
  font-family: "DM Sans" !important;
  .card-anchor {
    color: #1b3e61;
    text-decoration: underline;
    cursor: pointer;
  }
  .ml-0 {
    margin-left: 0 !important;
  }
  // milestone
  .milestone_name {
    font-size: 16px;
    font-weight: 700;
    margin-left: 6px;
  }
  .milestone-progress {
    width: 200px;
  }
  .milestone-cont {
    // position: fixed;
    overflow: hidden;
    border: 1px solid #dae9ff;
    border-radius: 16px;
    margin-top: 26px;
    width: 100%;
    margin-bottom: 24px;
    padding: 5px 15px;
    background-color: #f0f9ff;
    cursor: pointer;
  }
  .milestone_label {
    font-size: 14px;
    font-weight: 500;
    color: #0a1f44;
  }
  .milestone_value {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 500;
  }
  .left-side-milestone {
    width: 100%;
    margin-top: 8px;
    .ql-toolbar.ql-snow {
      border: 1px solid rgba(145, 158, 171, 0.25);
      padding: 8px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .ql-container.ql-snow {
      border: 1px solid rgba(145, 158, 171, 0.25);
    }
  }
  .right-side-milestone {
    width: 364px;
    border: 1px solid #919eab33;
    border-radius: 6px;
    margin-top: 35px;
  }
  .right-side-milestone .head {
    font-size: 16px;
    font-weight: 700;
    color: #212b36;
    padding: 11px 17px;
    background-color: #f2f4f6;
  }
  .grid-list-cont {
    flex-direction: row;
    border: 1px solid #919eab33;
    border-radius: 4px;
    justify-content: center;
    height: 36px;
    cursor: pointer;
  }
  .grid-list-cont img {
    padding: 9px;
    height: 19px;
  }
  .grid-list-cont > img:first-child {
    border-right: 1px solid #919eab33;
  }
  .manage-board {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #919eabcc !important;
    margin-left: 7px;
    cursor: pointer;
  }
  .btn-blue {
    background-color: #1b3e61;
    font-size: 16px;
    border-radius: 4px;
  }
  .btn-secondary-border {
    border: 1px solid #1b3e61;
    color: #1b3e61;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    // text-transform: capitalize;
  }
  .card-status {
    padding: 5px 10px;
    background-color: #f0f9ff80;
    border-radius: 4px;
    border: 1px solid #1b3e611f;
    margin: auto;
  }
  .user-avatar {
    width: 32px;
    height: 32px;
    font-size: 14px;
    // color: #1b3e61;
    // background-color: $avatar-bg-color;
  }
  // background-color: $body-background-color;

  // MUI Default design

  .MuiDialog-root .MuiModal-backdrop,
  .MuiDrawer-modal .MuiModal-backdrop {
    background-color: rgb(1 19 36 / 70%);
  }
  .MuiDialogTitle-root {
    padding: 12px 16px;
  }
  .assign-board-owner-list .MuiMenu-paper {
    width: 260px;
    ul {
      li {
        padding: 8px 8px;
        font-size: 14px;
        &.active-assign {
          font-weight: 500;
        }
      }
    }
  }
  .MuiDialogTitle-root {
    font-weight: 600;
    color: #1b3e61;
  }
  .MuiPopover-paper {
    -webkit-filter: drop-shadow(0px 4px 50px #1b3e6140);
    filter: drop-shadow(0px 4px 50px #1b3e6140);
    border-radius: 6px;
    border: 1px solid rgb(145 158 171 / 25%);
  }
  .MuiPopover-paper::before {
    border-left: 1px solid rgb(145 158 171 / 25%);
    border-top: 1px solid rgb(145 158 171 / 25%);
  }
  .MuiButton-endIcon {
    margin-left: 0px;
  }
  .MuiInputLabel-root {
    font-size: 16px;
    text-align: left;
    color: #919eab;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgb(145 158 171 / 25%);
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 16px 0 !important;
  }
  .MuiAccordionSummary-content {
    margin: 16px 0 !important;
  }
  .MuiAvatarGroup-root .MuiAvatar-root {
    border: 1px solid #c7ced4;
  }
  .MuiTabs-indicator {
    background: #fd7401;
  }
  .mui-tabs.Mui-selected {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
    text-align: left;
    color: #1b3e61;
  }
  .mui-status-field {
    .MuiOutlinedInput-input {
      padding: 11px 14px;
    }
  }
  .custom-progress-bg {
    background: rgba(145, 158, 171, 0.25);
    border-radius: 4px;
  }
  .custom-progress-bg-above {
    background: red;
    border-radius: 4px;
  }
  //   .MuiAvatar-root{
  // border: 1px solid rgba(145, 158, 171, 0.5) !important
  //   }
  // .MuiFormControl-root {
  //   font-family: DM Sans;
  //   font-size: 14px;
  //   font-weight: 400;
  //   line-height: 18.23px;
  //   text-align: left;
  //   color: #919eab;
  //   border: 1px solid #919eab40;
  //   border-radius: 4px;
  // }

  // MUI Default design ^

  .orange-color {
    background-color: #fd7401;
  }
  .dashboard-logo {
    width: -webkit-fill-available;
  }
  .theme-bg-color {
    background-color: #1b3e61;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .black-primary-color {
    color: #212b36;
  }
  .underline-on-hover:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  .d-flex {
    display: flex;
  }
  .flex_direction_row {
    flex-direction: row;
  }
  .position_relative {
    position: relative;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-left {
    justify-content: left;
  }
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-end-important {
    justify-content: end !important;
  }
  .justify-content-space-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .align_text_center {
    text-align: center;
  }
  .fixed-ui {
    position: fixed;
    width: calc(100% - 340px);
  }
  .remove-container-width {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .main-container {
    padding: 24px 40px;
  }
  .text-center {
    text-align: center;
  }
  .text-end {
    text-align: end;
  }
  .color-white {
    color: $color-white;
  }
  .color-black {
    color: $color-black;
  }
  .font-size-14 {
    font-size: 14px;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .pl-2 {
    padding-left: 8px !important;
  }
  .pl-38 {
    padding-left: 38px;
  }
  .pa-0 {
    padding: 0;
  }
  .m-0 {
    margin: 0 !important;
  }
  .ml-1 {
    margin-left: 6px;
  }
  .ml-2 {
    margin-left: 8px;
  }
  .ml-3 {
    margin-left: 12px;
  }
  .ml-44 {
    margin-left: 44px;
  }
  .mr-40 {
    margin-right: 40px;
  }
  .mr-44 {
    margin-right: 44px;
  }
  .mr-32 {
    margin-right: 32px;
  }
  .ml-30 {
    margin-left: 30px;
  }
  .mr-1 {
    margin-right: 5px;
  }
  .mr-2 {
    margin-right: 8px;
  }
  .mr-3 {
    margin-right: 12px;
  }
  .mb-6 {
    margin-bottom: 36px;
  }
  .mb-4 {
    margin-bottom: 16px;
  }
  .mb-2 {
    margin-bottom: 8px;
  }
  .mt-4 {
    margin-top: 16px;
  }
  .mt-1 {
    margin-top: 4px;
  }
  .mt-2 {
    margin-top: 8px;
  }
  .w-100 {
    width: 100%;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .mt-150 {
    margin-top: 150px;
  }
  .mt-65 {
    margin-top: 65px;
  }
  .mt-32 {
    margin-top: 32px;
  }
  .mt-11 {
    margin-top: 11px;
  }
  .w-25 {
    width: 110px;
  }
  .weight-700 {
    font-weight: 700;
  }
  .weight-500 {
    font-weight: 500;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-14 {
    font-size: 14px;
  }
  .text-color {
    color: #1b3e61;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .checkbox-mui-button {
    border: 1px solid #e9ecef;
    color: #212b36;
  }
  //////////////////////////   Admin Pannel  ///////////////////////

  .center-header {
    .MuiDataGrid-columnHeaderTitleContainerContent {
      width: 100% !important;
    }
  }
  .admin-navigation-containor {
    margin-left: 50px;
  }
  // .admin-navigation-btn{
  // font-size: 14px;
  // font-weight: 400;
  // line-height: 18.23px;
  // text-transform: capitalize;
  // color:  #212B36CC;
  // }
  .admin-navigation-containor {
    a {
      button {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.23px;
        text-transform: capitalize;
        color: #212b36cc;
      }
      &.active {
        button {
          font-size: 14px;
          font-weight: 700;
          line-height: 18.23px;
          color: #1b3e61;
        }
      }
    }
  }

  .admin-header-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.25px;

    color: #212b36;
  }
  .sidebar-create-board {
    li {
      // position: fixed;
      // bottom: 0;
      padding: 15px;
      left: 0;
      max-width: 260px;
      .button-create-board {
        background-color: $theme-color;
        border-radius: $button-border-radius;
      }
    }
  }

  .filter-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #1b3e61;
  }

  .board-workflow-change-item {
    padding: 9px 12px;
    background-color: #ededed;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
  }
  .radio-btn-label .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 500;
    color: #212b3699;
  }
  .active-radio-btn .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 500;
    color: #1b3e61;
  }
  .active-radio-btn input {
    width: 10px;
  }
  .active-radio-btn .Mui-checked {
    color: #00b8d9;
  }
  .comments-react-quill {
    font-size: 0.75rem;
    font-weight: 400;
    color: #d32f2f;
    margin-left: 20px;
    margin-top: 15px;
  }

  //////////////////////////   Admin Pannel  ///////////////////////

  //////// System Settings ////////
  ///
  .logo-note {
    color: #212b3699;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 11px;
  }
  .ldap-note {
    .logo-note {
      color: #212b3699;
      font-size: 12px;
      margin-bottom: 8px;
      font-style: normal;
    }
  }
  .form-section {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
  }
  //////// System Settings END ////////

  .accordian-summary-group-by-user .MuiAccordionSummary-expandIconWrapper {
    // width: 110px;
    // justify-content: end;
    display: none;
  }
  .text-700-theme-color {
    // font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.23px;
    // text-align: left;
    color: #212b36;
  }

  .assigned-board {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    padding-left: 10px;
    color: rgb(145, 158, 171);
    letter-spacing: 0.5px;
  }

  // .menu-AccordionSummary.Mui-expanded {
  //   padding-top: 8px;
  //   min-height: auto !important;
  // }
  // .MuiAccordionSummary-content.Mui-expanded {
  //   margin: 10px 0;
  // }
  .sidebar-board-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgb(227 231 235);
    background-color: rgb(255 255 255);
  }
  // .coloumn-all-cards::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3);
  //   background-color: #ffffff;
  // }

  .sidebar-board-list::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(255 255 255);
  }
  // .coloumn-all-cards::-webkit-scrollbar {
  //   width: 3px;
  //   background-color: #1b3e61;
  // }
  // .coloumn-all-cards::-webkit-scrollbar-thumb {
  //   background-color: #1b3e611f;
  // }

  .sidebar-board-list::-webkit-scrollbar-thumb {
    background-color: rgb(227 231 235);
  }
  .created-date-worklog {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    opacity: 50%;
    white-space: nowrap;
    color: rgb(33, 43, 54);
  }
  .white-button {
    background-color: $white-button-bg;
    color: $white-button-color;
    border: 1px solid $white-button-border-color;
    border-radius: $button-border-radius;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: "DM Sans";
  }
  .file-upload-button {
    height: 56px;
    &:hover {
      background-color: $white-button-bg;
      color: $white-button-color;
      border: 1px solid $white-button-border-color;
    }
  }
  .orange-button {
    background-color: $primary-button-bg-color;
    color: $color-white;
    border: 1px solid $primary-button-bg-color;
    border-radius: $button-border-radius;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: "DM Sans";
    &:hover {
      background-color: $primary-button-bg-color;
      color: $color-white;
      border: 1px solid $primary-button-bg-color;
    }
  }
  .blue-text-color {
    color: $blue-text-color;
  }
  .mr-5 {
    margin-right: 5px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .user-avtar-dropdown {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #c7ced4;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .d-grid {
    display: grid;
  }
  .width-100 {
    width: 100%;
  }
  .height-100 {
    height: 100%;
  }
  .vertical-scrollbar {
    overflow: auto;
  }

  .header-avatar {
    font-size: 14px;
    height: 32px;
    width: 32px;
    border: 1px solid #c7ced4;
  }
  .card-avtar {
    height: 32px;
    width: 32px;
    border: 1px solid #c7ced4;
    cursor: pointer;
    // background: #dce6ec;
    // color: #1b3e61;
    font-size: 12px;
    font-weight: 600;
  }
  .avatar-group > div {
    font-size: 12px;
    // background: #dce6ec;
    font-weight: 600;
    height: 32px;
    width: 32px;
    // color: #1b3e61;
    // border: 1px solid rgba(145, 158, 171, 0.5) !important;
  }

  .bold-heading-700 {
    font-weight: 600;
  }
  .domain-static {
    background: #f2f6fe;
    padding: 15px 10px;
    border-radius: 4px;
    p {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .ldap-note ul {
    padding-left: 20px;
  }
  .setting-heading {
    font-size: 18px;
    font-weight: 700;
  }
  .ldap-note ul li {
    margin-bottom: 4px;
    font-size: 15px;
  }
  .ldap-note p {
    font-size: 15px;
  }
  .logos-pensil {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .branding-cont {
    margin-top: 10px;
    padding: 30px;
    border: 1px solid #1b3e6114;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 370px;
  }
  .branding-icon-cont img {
    justify-content: center;
    // align-items: center;
    // height: 70px;
    width: auto;
    max-width: 100%;
  }
  .hover-button {
    display: none; /* Hide the button initially */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #f0f9ff;
    z-index: 9999;
  }

  .branding-cont:hover .hover-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .branding-fav-cont {
    margin-top: 10px;
    padding: 30px;
    border: 1px solid #1b3e6114;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100px;
  }
  .branding-favicon-cont img {
    justify-content: center;
    // align-items: center;
    height: 64px;
    width: auto;
    max-width: 100%;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
}
.header-owner-logo {
  margin-left: 6px;
}
.board-name-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: left;
  color: #212b36cc;
  text-transform: capitalize;
}
.archive-button {
  width: 36px !important;
  height: 36px !important;
  min-width: 1px !important;
  margin-left: 8px !important;
}
.archive-popover {
  ul {
    padding: 0;
    button {
      text-transform: none;
      padding: 8px 16px;
    }
  }
}
.attach-btn {
  margin-left: 8px !important;
  height: 36px;
  padding: 0px 10px;
}
.epics-btn {
  height: 36px;
  img {
    margin-right: 4px;
  }
  .MuiButton-endIcon {
    margin-left: 8px;
    svg {
      fill: #919eab;
      opacity: 0.5;
      font-size: 24px;
    }
  }
}
.attachment-action-img {
  height: 20px;
  cursor: pointer;
}
.attachment-header-text {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
}
.attachment-list-header-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.23px;
  text-align: left;
}

.empty-attachment-list {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.attachment-name-text {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: left;
  color: #212b36;
  margin-bottom: 2px;
}
.attachment-file-name-text {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.93px;
  text-align: left;
  color: #212b36b2;
}

.css-hz1bth-MuiDialog-container {
  height: inherit !important;
}

.loader-center {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 60vh;
}

.create-new-password-info {
  color: #212b36cc;
  font-size: 16px;
  text-align: center;
}

// board header
.board-field-name {
  margin-left: 8px !important;
  // width: calc(80% - 64px);
  width: 100%;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.25px;
  text-align: left;
  color: #212b36;
  text-transform: capitalize;
}
.select-user {
  margin-left: 8px !important;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 31.25px;
  text-align: left;
  color: #212b36;
  text-transform: capitalize;
}
.board-heading-name {
  margin-left: 8px !important;
  width: 100%;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.25px;
  text-align: left;
  color: #212b36;
  text-transform: capitalize;
  .MuiInputBase-input {
    margin-left: 8px !important;
    width: 100%;
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.25px;
    text-align: left;
    color: #212b36;
    text-transform: capitalize;
  }
}
.user-heading-name {
  margin-left: 8px !important;
  align-self: center;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  color: #212b36;
  text-transform: capitalize;
}

.header-action-button-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  text-transform: capitalize;
}

// Coloumns

.coloumn-header {
  align-content: center;
}
.coloumn-all-cards {
  // height: 58vh;

  overflow-y: auto;
  overflow-x: hidden;
}
.coloumn-all-cards-task-page {
  // height: 47vh;
  height: calc(94vh - 191px);
  // min-height: 27vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.coloumn-header-h4 {
  display: flex;
  align-items: center;
}
.coloumn-header-text {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.62px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #212b36;
}
.card-id-number {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  color: #1b3e61;
}
.create-card-btn-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: left;
  color: #1b3e61;
  margin-left: 4px;
}
.coloumn-header-length {
  background: rgba(27, 62, 97, 0.12);
  border-radius: 50%;
  padding: 2px;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}
.card-style {
  background-color: white;
  padding: 8px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  border-radius: 6px;
  text-align: left;
  border: 1px solid rgba(145, 158, 171, 0.2);
  flex-grow: 1;
  // display: flex;
  // flex-direction: column; // Stacks items vertically
  // justify-content: space-between; // Adds space between the items vertically
  // height: 100%;
}
.card-style-blur {
  background-color: white;
  padding: 8px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  border-radius: 6px;
  text-align: left;
  border: 1px solid rgba(145, 158, 171, 0.2);
  flex-grow: 1;
  display: flex;
  flex-direction: column; // Stacks items vertically
  justify-content: space-between; // Adds space between the items vertically
  opacity: 0.5;
  // height: 100%;
}
.card-title-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  min-height: 21px;
  color: #212b36;
  // width: 90%;
}
.card-level-tag {
  // width: 111px;
  // height: 20px;
  // top: 228px;
  // left: 56px;
  // gap: 0px;
  margin-top: 8px;
  width: fit-content;
  border-radius: 4px;
  background: rgba(253, 116, 1, 0.08);
}
.card-level-tag-text {
  color: rgba(253, 116, 1, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  padding: 5px;
}
.card-epic-tag {
  // width: 111px;
  // height: 20px;
  // top: 228px;
  // left: 56px;
  // gap: 0px;
  margin-top: 8px;
  width: fit-content;
  border-radius: 4px;
  // background: rgba(253, 116, 1, 0.08);
  background: rgba(144, 78, 226, 0.16);
}
.card-epic-tag-text {
  // color: rgba(253, 116, 1, 1);
  color: rgba(144, 78, 226, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  padding: 5px;
}
.card-bottom {
  margin-top: 8px;
  margin-bottom: 0;
}
// .card-bottom p {
//   font-size: small;
// }
.assign-board-owner-list ul {
  max-height: 40vh;
  overflow-y: auto;
  padding: 8px;
}
.board-owner-avtar-click {
  cursor: pointer;
}
.remove-user-delete-icon {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  color: #bdbdbd;
}
.fixed-div {
  position: fixed;
  bottom: 22px;
  right: 22px;
  background-color: #dbdbdb;
  // padding: 10px;
  // border: 1px solid #999;
  // transition: scroll 0.3s ease-in-out;
}
.board-card-scroll-btn {
  scroll-behavior: smooth;
}
.board-card-scroll-btn-new-board {
  // scroll-behavior: smooth;width: 600px; /* Adjust width as needed */
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Prevents vertical scrolling */
  white-space: nowrap; /* Prevents line breaks to maintain horizontal flow */
  // padding: 8px; /* Optional padding for inner spacing */
  // border: 1px solid #ddd;
}

.scroll-x {
  overflow-x: scroll;
}

.create-card-btn {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: left;
  text-transform: capitalize !important;
}
.create-card-error-textarea:focus {
  border: 1.5px solid red !important;
}
.create-card-textarea {
  border-radius: 6px;
  border: 1.5px solid rgba(253, 116, 1, 1);
  padding: 10px;

  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #212b36;
  padding-bottom: 32px;
}
.create-card-textarea:focus {
  border-radius: 6px;
  outline: none !important;
  border: 1.5px solid rgba(253, 116, 1, 1);
}
.card-error-font-size {
  font-size: 12px !important;
}

.edit-card-textarea {
  border-radius: 6px;
  border: none;
  padding: 10px;

  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #212b36;
}
.edit-card-textarea:focus {
  border-radius: 6px;
  outline: none !important;
  border: none;
}
.edit-card-textarea-subtask {
  border-radius: 6px;
  border: none;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #212b36;
}

.active-assign {
  border-radius: 4px !important;
  border: 1.8px solid rgba(253, 116, 1, 1) !important;
}
.font-bold {
  font-weight: 600 !important;
}

.dialog-content-form {
  padding: 20px 16px !important;
}

.active-avtar {
  border: 1px solid #f18041 !important;
}

.active-avtar-mbl {
  border: 1px solid #1976d2 !important;
}

// filters

.filter-text {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #1b3e61;
  margin-right: 12px;
  text-wrap: nowrap;
}
.filter-btn {
  height: 36px;
  border-radius: 4px 0px 0px 0px;
  // opacity: 0px;
  padding: 7px 8px !important;
}

.custom-border {
  // height: 0px;

  // border: 1px 0px 0px 0px;
  border-top: 1px solid #919eab40 !important;
  padding: 12px 8px !important;
}
.board-name-filter {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
}
/* Hide the default checkbox */
.custom-checkbox input {
  display: none;
}

/* Create a custom checkbox */
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

/* Create the checkmark/indicator (hidden by default) */
.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  // background-color: #ccc; /* Default background color */
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
  border: 1px solid #919eab;
}

/* When the checkbox is checked, show the indicator */
.custom-checkbox input:checked + .checkmark {
  background-color: #00b8d9;
  border-color: #00b8d9;
}

/* Add the checkmark/indicator (hidden by default) */
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark::after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.see-worklogs {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.83px;
  color: #ffffff;
  text-transform: capitalize !important;
  background-color: #1b3e61 !important;
}
.submit-date-picker {
  position: absolute;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.18000000715255737px;
  // color: #00B8D9 !important;
  background-color: rgb(27, 62, 97) !important;

  width: 43px !important;
  height: 20px !important;
  margin-left: 12px !important;
  bottom: 18px;
  padding: 14px !important;
}
.reset-date-picker {
  padding: 14px !important;
  position: absolute;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.18000000715255737px;
  color: #00b8d9 !important;
  width: 43px !important;
  height: 20px !important;
  margin-left: 12px !important;
  bottom: 18px;
}

.fix-header-with-white {
  background-color: white;
  padding: 24px 40px 14px 40px;
  margin-top: -24px;
  z-index: 9;
  margin-left: -40px;
}
.accordian-summary-group-by-user .MuiAccordionSummary-content {
  margin: 8px 0 !important;
}
.accordian-summary-group-by-user {
  // background: #f2f4f6 !important;
}
.border-not-top {
  border-bottom: 1px solid rgba(145, 158, 171, 0.122) !important;
  border-left: 1px solid rgba(145, 158, 171, 0.122) !important;
  border-right: 1px solid rgba(145, 158, 171, 0.122) !important;
}

.accordion-allocation-board {
  // border: 1px solid #8080802b;
  // border-radius: 8px;
  box-shadow: none !important;
  // border:  1px solid rgba(145, 158, 171, 0.122) !important;
}
.grouping-status-chips {
  // background: #fff !important;
  // border: 1px solid #ddd !important;
}
.cards-tab-panel {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
  color: #1b3e61;
}

// Toggle button

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 25px;
  outline: none;
}

.switch input {
  // position: absolute;
  // top: -99999px;
  // left: -99999px;
  visibility: hidden;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b3e613d;
  box-shadow: 0px 4px 16px 0px #1b3e610f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

/* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
} */
.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  /* background-color: rgb(20, 37, 103); */
  background-color: #1b3e61;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(20, 37, 103);
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
.team-header {
  background: #f2f4f6;

  height: 44px;
  border: 1px solid rgba(145, 158, 171, 0.122);
  border-radius: 5px 5px 0px 0px;
  padding: 0 20px;
}
.star {
  color: #ff5630;
}
.milestone-lable {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.team-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #1b3e61;
}
.team-status-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: #212b36;
  align-self: center;
}
.side-bar-icon {
  width: 16px;
}
.coloumn-all-cards-task-page-1 {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.coloumn-all-cards-task-page-2 {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.coloumn-all-cards-task-page-3 {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
.login_welcome_mobile {
  // font-size: 14px !important;
}
.workflow_steps_cont {
  // max-height: 124px;
  overflow: auto;
  margin: 8px 0px 16px 0px;
}
.workflow_steps {
  padding: 5px;
  font-size: 14px !important;
  background-color: rgb(242, 244, 246);
  margin-top: 4px !important;
  border-radius: 4px;
}
.pos-sticky {
  position: sticky;
}
.edit-milestone-text-editor-btn-div {
  margin-top: 56px;
}
.seen-mobile {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 0px;
}
.related_time {
  font-size: 11px;
  font-weight: 500;
  color: #212b3666;
}
.priority_icon {
  align-self: baseline;
  margin-left: 6px;
  width: 15px;
  margin-top: 6px;
}
.priority_cont {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: rgb(33, 43, 54);
  }
}
.search_worklog_cont {
  width: 190px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -4px;
}
.worklog_parent {
  margin-top: 8px;
  margin-bottom: 8px;
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: -2px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 19px;
    outline: none;
  }
  .group_icon {
    width: 20px;
  }
}
.create-card-div {
  position: absolute;
  width: 250px;
  // z-index: 111;
  background-color: rgb(242, 244, 246);
  margin-top: -26px;
}
.subtask-container {
  padding: 5px 16px;
  margin-bottom: 8px;
}
// .subtask-outerContainer {
//   max-height: 135px;
//   overflow-y: auto;
// }
.merge-milestone-div {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mergeMilestone-dialogActions {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding: 0px 22px 0px 22px !important;
}
.forgot-password-div {
  display: flex;
  justify-content: end;
}
.forgot-password-para {
  font-weight: 400;
  font-size: 16px;
  // line-height: 21px;
  color: rgba(33, 43, 54, 0.8);
}
.login_logo {
  max-width: 280px;
  width: auto;
  height: auto;
}
.reset_pass_icon {
  width: 24px;
  cursor: pointer;
}
.internal_user_icon {
  width: 18px;
}
.external_user_icon {
  width: 18px;
  opacity: 0.5;
}
.parent-table-box {
  height: 75vh !important;
}
.parent-milestone-table-box {
  height: 65vh !important;
}
.h-70vh {
  height: 70vh;
}
.global-table th {
  position: sticky;
  top: 0;
  background-color: rgb(242, 244, 246);
  z-index: 2; /* Ensure it stays above the body rows */
}
.merge-mileston {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #0a1f44;
}
.popup-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.w-70 {
  width: 70px;
}
.w-38 {
  width: 38px;
}
.w-23 {
  width: 23px;
}
.table-header-listview {
  background-color: rgba(242, 244, 246, 1);

  tr {
    th {
      span {
        color: rgba(27, 62, 97, 1);
        font-size: 16px;
      }
    }
  }
}
.listView-fontColor {
  color: rgba(33, 43, 54, 0.8) !important;
}

.d-inline-flex {
  display: inline-flex;
}

.milestone-description {
  height: 460px;
  border: 0.3px solid rgb(0, 0, 0, 0.09);
  padding: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.remove-default-button-style {
  all: unset;
}
.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
  border: 1px solid #919eab;
}
.clear-filter-color {
  color: #1976d2 !important;
}
.word-wrap-break-word {
  word-wrap: break-word;
}
.dialog-title-bgcolor {
  background-color: rgba(242, 246, 254, 1);
}
.add-attachment-btn {
  // background-color: rgba(27, 62, 97, 1) !important;
  border-radius: 4px;
  &:disabled {
    background-color: rgba(
      0,
      0,
      0,
      0.12
    ) !important; /* Light grey background for disabled */
    cursor: not-allowed;
  }
  min-width: 80px;
  text-transform: capitalize;
  height: 40px;
}
.add-attachment-crossBtn {
  position: absolute !important;
  right: 12px;
  top: 12px;
  color: #9e9e9e !important;
}
.attachment-form-box {
  background-color: rgba(242, 244, 246, 1);
  margin-bottom: 5px;
  border: 1px solid rgba(145, 158, 171, 0.12);
  border-radius: 8px;
}
.attachment-name-field {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #919eab40;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.cursor-default {
  cursor: default !important;
}

.card-detail-dialog .card-detail-content {
  position: relative; /* Ensure the hover styles are scoped */
}

.display-card-icons .edit-icon,
.display-card-icons .delete-icon
// .card-detail-dialog .card-detail-prev-icon,
// .card-detail-dialog .card-detail-next-icon
{
  display: none;
}

.display-card-icons:hover .edit-icon,
.display-card-icons:hover .delete-icon
// .card-detail-dialog:hover .card-detail-prev-icon,
// .card-detail-dialog:hover .card-detail-next-icon
{
  display: flex;
}
.display-card-icons .edit-icon {
  width: 12px;
}

.card-detail-dialog {
  // .MuiDialogContent-root{
  .card-detail-next-icon,
  .card-detail-prev-icon {
    display: none;
  }
  // }
}

// .card-detail-dialog:hover{
.MuiDialog-paper:hover {
  .card-detail-next-icon,
  .card-detail-prev-icon {
    display: flex;
  }
  // }
}
.change-board-btn {
  .css-9tj150-MuiButton-endIcon {
    margin-right: 0px;
  }
  .css-1d6wzja-MuiButton-startIcon {
    margin-left: 0px;
  }
}
.subtask-tooltip {
  transition-delay: 1s;
}

.min-width-60{
  min-width: 60px;
}
.align-items-baseline{
  align-items: baseline;
}
.flex-wrap{
  flex-wrap: wrap;
}
.watching-card-status{
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #1b3e611f;
  margin: auto;
  background: rgba(253, 116, 1, 0.08);
  border: 1px solid rgba(253, 116, 1, 0.25);

  color: rgb(250, 116, 6);
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

@media screen and (max-width: 992px) {
  .mobile_avatar {
    width: 24px !important;
    height: 24px !important;
  }
  .fixed-ui {
    position: sticky !important;
    width: 100% !important;
  }
  .board-heading-name {
    font-size: 20px !important;
  }
  .remove-container-width {
    padding: 12px !important;
    background-color: #fff !important;
    z-index: 999;
  }
  .white-button {
    border: none !important;
  }
  .flex-dir-col {
    flex-direction: column !important;
  }
  .max-width-100 {
    max-width: 100% !important;
  }
  .margin-bottom-10 {
    margin-bottom: 10px !important;
  }
  .padding-left-0 {
    padding-left: 0px !important;
  }
  .padding-top-0 {
    padding-top: 5px !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .padding-right-0 {
    padding-right: 0px !important;
  }
  .attachment-download-div {
    margin-left: -15px;
    margin-right: 10px;
  }
  .attachment-content {
    padding: 20px 14px !important;
  }
  .attachment-text {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.23px;
    text-align: left;
  }
  .add-user-form {
    padding: 8px 10px !important;
  }
  .padding-top-16 {
    padding-top: 16px;
  }
  .create-milestone-content {
    padding: 8px 16px !important;
  }
  .mobile-worklog-box-body {
    margin-left: 13px;
    width: 100%;
    // width: 695px;
  }
  .mobile-worklog-box {
    display: flex;
    margin-left: 4px;
    margin-top: 35px;
    // width: 746px;
  }
  .mobile-font {
    font-size: 14px !important;
  }
  .ql-toolbar {
    padding: 5px !important;
  }
  .milestone-cont {
    margin-top: 16px !important;
    width: 100%;
    margin-bottom: 24px;
    padding: 5px 15px;
    background-color: #f0f9ff;
    cursor: pointer;
  }
  .milestone_name {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  .custom-checkbox .checkmark {
    width: 14px;
    height: 14px;
  }

  .milestone-progress {
    width: 150px !important;
  }

  .milestone_label {
    font-size: 13px !important;
  }

  .milestone_value {
    font-size: 13px !important;
  }

  .milestone-cont {
    margin-bottom: 0px !important;
  }

  .board-heading-name {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .board-name-filter {
    font-size: 13px !important;
  }

  .custom-border {
    padding: 8px 8px !important;
    min-height: 30px !important;
  }

  .see-worklogs {
    font-size: 13px !important;
    font-weight: 700 !important;
    padding: 4px 8px !important;
  }

  .active-radio-btn .MuiFormControlLabel-label {
    font-size: 12px !important;
  }

  .radio-btn-label .MuiFormControlLabel-label {
    font-size: 12px !important;
  }
  // .mobile-cardDetail-arrow{
  //   // margin-right: 16px;
  // }
  .mobile-project-name {
    margin-right: 8px;
    color: rgba(27, 62, 97, 1);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
  .mobile-modal-header-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px; /* Limit max width of the project name */
    width: 90%; /* Ensure it takes full width */
  }
  .ipad-modal-header-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%; /* Ensure it takes full width */
  }
  .mobile-attachment {
    margin-right: 12px;
  }
  .mobile-left-heading {
    display: flex;
    align-items: center;
  }
  .mobile-attach-btn {
    margin-right: 12px !important;
    margin-bottom: 3px !important;
    padding: 5px 7px !important;
    min-width: 0px !important;
  }
  .mobile-text-editor-box {
    height: 250px;
  }
  .mobile-textEditor-btn-div {
    margin-top: 50px;
  }
  .mobile-sidebar-heading {
    margin-bottom: 10px !important;
    width: 100%; /* Ensure full width */
    margin-top: 10px !important;
  }
  .mobile-activity-dropdown {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    align-items: center;
    margin-top: 8px;
  }
  .mobile-attachment-box {
    width: 100%;
    height: 70px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mobile-comment-box {
    display: flex;
    margin-left: 8px;
    margin-top: 40px;
    width: 100%;
  }

  .mobile-comment-box-body {
    width: 100%;
    margin-left: 10px;
  }
  #mobile-editor2 {
    border: none;
    box-shadow: none;
    .ql-editor {
      ul,
      ol {
        padding-left: 0px;
        // li{
        //     padding-left: 1.0em;
        // }
      }
      padding: 0px;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      // line-height: 20px;
      // text-align: left;
      margin-top: 5px;
      opacity: 0.8;
      width: 100%;
      // margin-left: -5px;
    }

    &.ql-blank::before {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      // line-height: 18.23px;
      // margin-left: 5px;
      opacity: 0.8;
      // content: attr(data-placeholder); // Use the placeholder attribute for empty content
    }
  }
  .mobile-edit-comment-textEditor {
    // margin-left: 12px;
    // margin-top: -20px;
    // margin-left: -5px;
    width: 97%;

    .ql-toolbar.ql-snow {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 87px !important;
      padding: 5px !important;
      border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .ql-container {
      height: 150px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .text-editor-btn-div {
      margin-top: 16px;

      .text-editor-btn1 {
        height: 36px !important;
        width: auto !important;
        background-color: #1b3e61 !important;
        min-width: 90px !important;
        box-shadow: none !important;

        .text-editor-btn-content {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 16px;
        }
      }
      .text-editor-btn2 {
        margin-left: 10px !important;
        height: 36px !important;
        width: auto !important;
        min-width: 90px !important;
        color: black !important;
        opacity: 0.5 !important;
        border: 1px solid rgba(13, 20, 97, 0.5) !important;

        .text-editor-btn-content {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 16px;
        }
      }
    }
  }
  .mobile-comment-textEditor {
    margin-left: 10px;
    width: 100%;

    .ql-toolbar.ql-snow {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 87px !important;
      padding: 5px !important;
      border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .ql-container {
      height: 150px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid rgba(145, 158, 171, 0.25);
    }

    .text-editor-btn-div {
      margin-top: 16px;

      .text-editor-btn1 {
        height: 36px !important;
        width: auto !important;
        background-color: #1b3e61 !important;
        min-width: 90px !important;
        box-shadow: none !important;

        .text-editor-btn-content {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 16px;
        }
      }
      .text-editor-btn2 {
        margin-left: 10px !important;
        height: 36px !important;
        width: auto !important;
        min-width: 90px !important;
        color: black !important;
        opacity: 0.5 !important;
        border: 1px solid rgba(13, 20, 97, 0.5) !important;

        .text-editor-btn-content {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 16px;
        }
      }
    }
  }
  .mobile-comment-box-date {
    // margin-top: 16px !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18.23px !important;
    color: #212b36 !important;
    opacity: 0.7 !important;
  }
  // .mobile-work-discription-title{
  //   font-family: DM Sans;
  //   font-weight: 500;
  //   opacity: 0.6;
  //   margin-top: 10px;
  // }
  .mobile-ticket-reasign-step-container {
    background-color: rgba(145, 158, 171, 0.12);
    border-radius: 4px;
    padding: 0px 16px;
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 180px;
    .initial-step {
      // max-width: 180px;
      // padding : 15px 0px 15px 10px;
      border-radius: 4px;
      background-color: white;
      margin-bottom: 15px;
      p {
        font-weight: 500;
        font-size: 14px;
        color: rgba(33, 43, 54, 1);
        line-height: 19px;
      }
    }
    img {
      height: 25px;
      width: 25px;
      rotate: 90deg;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }
  .mobile-current-target-board-name {
    width: 97% !important;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: rgba(27, 62, 97, 0.5);
      margin-top: 8px;
      margin-bottom: 5px;
    }
  }
  .mobile-edit-text {
    overflow-wrap: break-word;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 26.04px !important;
    // text-align: left;
    color: rgba(27, 62, 97, 1) !important;
  }
  .confirmation-title {
    padding: 8px 10px !important;
    font-size: 14px !important;
  }
  .confirmation-content {
    padding: 10px 20px !important;
  }
  .confirmation-contentText {
    font-size: 14px !important;
    text-align: center;
  }
  .confirmation-dialogAction {
    margin-bottom: 15px !important;
  }
  .confirmation-action-btn {
    font-size: 14px !important;
    padding: 5px 15px !important;
    min-width: 0px !important;
  }
  .add-checkpoint-dialogTitle {
    padding: 8px 10px !important;
    font-size: 16px !important;
  }
  .add-checkpoint-dialogContent {
    padding-top: 0px !important ;
    padding-bottom: 0px !important;
  }
  .create-milestone-title {
    padding: 8px 10px !important;
    font-size: 16px !important;
  }
  .edit-worklog-title {
    padding: 8px 10px !important;
  }
  .isLocked {
    padding-top: 10px 24px !important;
    p {
      line-height: 16px !important;
    }
  }
  .edit-milestone-text-editor-btn-div {
    margin-top: 75px;
  }
  .milestone-description {
    height: 184px;
  }
  .attachment-name-field {
    margin-bottom: 10px;
  }
  .add-attachment-btn {
    height: 35px;
  }
  .file-upload-button {
    height: 30px;
  }
  .create-card-div {
    position: relative;
    margin: 10px 0px;
    width: 118px;
  }
}

.subtasks.subtasks-uline {
  position: relative;
}
.subtask {
  position: relative;
}
.subtask {
  position: relative;
  margin-top: 5px;
  font-size: 14px;
  padding-left: 22px;
}
.subtasks.subtasks-uline:before {
  content: "";
  position: absolute;
  left: 0;
  width: 16px;
  background: url(../svg/parent-line.svg);
  height: calc(100% - 6px);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  opacity: 0.18;
  top: 0;
}
.subtask:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 15px;
  background: url(../svg/sub-child-arrow.svg);
  height: 9px;
  opacity: 0.18;
}
.subtasks.subtasks-uline .subtask:last-child:before {
  content: none;
}

.display-none {
  display: none !important;
}
.table-header {
  background-color: #f5f8fc !important;
  color: #1b3e61 !important;
  height: 44px !important;
}
.parent-table-boarder {
  border: 1px solid lightgray;
}
.mobile_board_head {
  position: sticky;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1px 5px -2px gray;
  z-index: 2;
}
.mobile_select_workflow {
  padding: 8px !important;
  font-size: 14px !important;
  min-height: 24px !important;
}
.mobile_main_cont {
  background-color: #f2f6fe;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  justify-content: space-between;
  padding: 8px;
  margin: 8px;
  overflow: auto;
}
