.uploadCSV-title {
  background-color: rgba(242, 246, 254, 1);
  border-bottom: 1px solid rgba(27, 62, 97, 0.16);
  color: rgba(27, 62, 97, 1);
}
.uploadCSV-box {
  max-height: 296px;
  max-width: 531px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  padding: 56px 145px 56px 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 24px;
  }
  .p-1 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
    text-align: center;
  }
  .p-2 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 400;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 24px;
  }
  .select-file-btn {
    padding: 12px 16px;
    background-color: rgba(251, 253, 254, 1);
    color: rgba(15, 145, 210, 0.7);
    border: 1px solid rgba(15, 145, 210, 0.7);
    border-radius: 5px;
    cursor: pointer;
  }
}
.invalidCSVError {
  text-align: center;
  height: 30px;
  width: 95%;
  color: #d32f2f; /* Dark red color for the error message */
  background-color: #fdecea; /* Light red background for visibility */
  border: 1px solid #d32f2f; /* Border color matching the text color */
  border-radius: 4px; /* Rounded corners for a softer look */
  padding: 8px 12px; /* Padding inside the message */
  font-size: 14px; /* Font size for readability */
  margin: 10px 0; /* Space around the message */
  display: flex; /* Flexbox to center content */
  align-items: center; /* Center items vertically */
  gap: 8px;
}

@media screen and (max-width: 992px) {
  .uploadCSV-box {
    padding: 20px;
    img {
      margin-bottom: 16px;
    }
    .select-file-btn {
      padding: 5px 16px;
      min-width: 0px;
    }
  }
  .uploadCSV-title {
    font-size: 16px !important;
    padding: 8px 10px !important;
  }
}
