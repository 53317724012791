.project-name {
  margin-right: 8px;
  color: rgba(27, 62, 97, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.project-status {
  padding-left: 5px;
  height: 28px;
  border-radius: 4px;
  background: rgba(253, 116, 1, 0.08);
  border: 1px solid rgba(253, 116, 1, 0.25);
}
.project-status-button {
  background: none;
  border: none;
  width: 100%;
  color: rgb(250, 116, 6);
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.project-status-icon {
  margin-top: 9px;
  color: rgb(250, 116, 6);
}
.project-desc-pop-right {
  margin-left: 535px;
}
// .attach-btn{

//     padding: 0px !important;
// }

.add-child-issue-btn {
  margin-left: 10px;
}

.add-child-issue-btn-content {
  text-transform: capitalize;
  margin-top: 2px;
  margin-left: 5px;
}
.attach-btn-content {
  text-transform: capitalize;
  // margin-top: 2px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(33, 43, 54, 1);
}
.modal-header-container {
  display: flex;
  align-items: center;
  height: auto; /* Allow height to increase as needed */
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.modal-header-inner-container {
  display: flex;
  justify-content: left;
  align-items: center;
  max-width: 500px; /* Limit max width of the project name */
  width: 100%; /* Ensure it takes full width */
}

.right-heading {
  margin-right: 40px;
  height: 36px;
}

.header-attach-btn,
.add-child-issue-btn {
  margin-left: 0px;
  margin-right: 0px;
  flex-shrink: 0; /* Prevent these buttons from shrinking */
}

.attach-btn-icon {
  padding: 5px 8px !important;
}

// .header {
//   padding-top: 11px !important;
//   padding-bottom: 10px !important;
// }

.parent-title-id {
  position: relative;
  display: inline-flex;
  padding-right: 20px;
}
.parent-title-id:after {
  content: "";
  position: absolute;
  right: 0;
  // background: url("../../../../styles/svg/parent-task-arrow\ .svg");
  background: url("../../../../styles/svg//parent-task-arrow\ .svg");
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 4px;
}
.subtasks.subtasks-uline {
  display: block;
  padding-top: 1px;
}

.parent-card-detail .parent-title-id {
  position: absolute;
  top: 4px;
  left: 10px;
  padding-right: 16px;
}
.parent-card-detail .modal-header-inner-container {
  margin-top: 6px;
}
.parent-card-detail .parent-title-id:after {
  width: 12px;
  height: 12px;
  top: 6px;
}
body .MuiDialogTitle-root.parent-card-detail {
  padding-left: 20px;
}

@media screen and (max-width: 992px) {
  .parent-card-detail .parent-title-id {
    top: 2px;
  }
  .parent-card-detail .parent-title-id:after {
    top: 4px;
  }
  .related_time {
    font-size: 12px !important;
  }
}
