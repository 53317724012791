@import "../../styles/css/variables";
body {
  .appbar-logo {
    max-height: 60px;
    // max-width: 100%;
    height: auto;
    // width: auto;
  }
  .wrapper_dashboard {
    // margin-top: 65px;
    display: flex;
    position: relative;
    min-height: calc(100vh - 65px);
    .wrapper_sidebar {
      position: fixed;
      min-height: 100vh;
      z-index: 9;
      border-right: 1px solid rgba(145, 158, 171, 0.25);
      width: 260px;
      background-color: $sidebar-bg-color;
      overflow: auto;
      max-height: 100vh;
      .logo-container {
        margin-top: 27px;
        margin-bottom: 13px;
        display: flex;
        justify-content: center;
      }
      .board-logo {
        width: 180px;
      }
      .sidebar-board-list {
        padding-bottom: 150px;
        overflow: auto;
        & > div {
          margin-bottom: 0px;
          border-radius: 0;
          margin-top: 0px;
          background: transparent;
          &.menu-Accordion::before {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
      .sidebar-board-list-for-create-button {
        max-height: calc(100vh - 143px);
      }
      .sidebar-board-list-for-not-create-button {
        max-height: calc(100vh - 80px);
      }
      .sidebar-create-board {
        li {
          position: fixed;
          bottom: 0;
          padding: 15px;
          left: 0;
          max-width: 260px;
          .button-create-board {
            background-color: $theme-color;
            border-radius: $button-border-radius;
          }
        }
      }
      .menu-AccordionSummary {
        & > div {
          align-items: center;
          justify-content: space-between;
          span.navigation-heading {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            padding-left: 10px;
            color: rgba(145, 158, 171, 1);

            letter-spacing: 0.5px;
          }
        }
        .MuiAccordionSummary-expandIconWrapper {
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          transform: rotate(270deg);
          svg {
            color: rgba(145, 158, 171, 1);
          }
          &.Mui-expanded {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
      .menu-AccordionDetails {
        padding: 0;
        padding-left: 16px;
        ul {
          background: transparent;
          li {
            padding: 0;
            margin-top: 4px;
            a {
              color: rgba(240, 249, 255, 0.7);
              text-decoration: none;
              padding: 8px 8px 8px 13px;
              display: flex;
              width: 100%;
              font-size: 15px;
              font-weight: 500;
              position: relative;
              align-items: center;
              &:hover {
                background-color: rgba(240, 249, 255, 1);
                // color: rgba(240, 249, 255, 1);
                &:before {
                  content: "";
                  width: 5px;
                  background-color: rgb(253, 116, 1);
                  height: 100%;
                  position: absolute;
                  left: 0;
                  border-radius: 4px 0px 0px 4px;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                }
              }
              &.active {
                background-color: rgba(240, 249, 255, 1);
                div {
                  border: 1px solid #1b3e61;
                }
                p {
                  font-weight: 600;
                  color: #1b3e61;
                }

                &:before {
                  content: "";
                  width: 5px;
                  background-color: rgb(253, 116, 1);
                  height: 100%;
                  position: absolute;
                  left: 0;
                  border-radius: 4px 0px 0px 4px;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                }
              }
              .sidebar-board-owner {
                font-size: 12px;
                width: 24px;
                height: 24px;
                border: 1px solid #c7ced4;
                border-radius: 50%;
                margin-right: 8px;
                // color: #1b3e61;
                // background-color: #e4e4e4;
                font-weight: 600;
              }
              .setting-icon-img {
                width: 16px;
                height: 16px;
                margin-left: 6px;
                color: rgba(27, 62, 97, 0.12);
              }
              p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: #212b36cc;

                overflow: hidden;
                // font-size: 14px;
                // font-weight: 700;

                font-family: DM Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 18.23px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .wrapper_main_container {
      width: calc(100% - 260px);
      margin-left: 260px;
    }
  }
}
.allocated-cards {
  margin-left: 4px;
  width: 20px;
  height: 18px;
  color: rgba(33, 43, 54, 1);
  font-style: italic;
}
@media screen and (max-width: 992px) {
  body {
    .wrapper_dashboard {
      .wrapper_sidebar {
        .sidebar-board-list {
          overflow: visible;
        }
        .sidebar-board-list-for-create-button {
          max-height: none;
        }
      }
    }
  }
}
