.custom-datePicker{
    height: 32px;
    // width: 180px;
    // border-rad: 1px solid rgba(145, 158, 171, 0.5);
}
input:focus {
    outline: none;
  }

// .due-Date-picker{
//     width: 180px;
//     height: 32px;
//     margin-right: 10px;
//     border-color: none;
// }
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  position: relative; 
  width: 100%;
}
.custom-datePicker {
  height: 32px;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #000;
}