.ticket-reasign-step {
  color: rgba(27, 62, 97, 1);
  font-weight: 700;
  font-size: 16px;
}
.ticket-reasign-step-container {
  background-color: rgba(145, 158, 171, 0.12);
  border-radius: 4px;
  padding: 0px 16px;
  // margin-top: 10px;
  display: flex;
  .initial-step {
    max-width: 180px;
    // padding : 15px 0px 15px 10px;
    border-radius: 4px;
    background-color: white;
    p {
      font-weight: 500;
      font-size: 14px;
      color: rgba(33, 43, 54, 1);
      line-height: 19px;
    }
  }
  img {
    padding: 27px 15px 0px 15px;
  }
}

.remove-step-btn-save {
  border-radius: 4px;
  &:disabled {
    background-color: rgba(
      0,
      0,
      0,
      0.12
    ) !important; /* Light grey background for disabled */
    cursor: not-allowed;
  }
  .btn-content {
    font-weight: 700;
    font-size: 16px;
    padding: 5.5px 18.5px;
    letter-spacing: 0px !important;
    text-transform: capitalize;
  }
}
.remove-step-btn-cancel {
  // padding: 12px 42px;
  margin-left: 15px !important;
  // height: 42px !important;
  // width: 130px !important;
  color: black !important;
  opacity: 0.5 !important;
  border: 1px solid rgba(13, 20, 97, 0.5) !important;
  // margin-top: 30px;
  // margin-bottom: 30px;
  .btn-content {
    font-weight: 700;
    font-size: 16px;
    // font-weight: bolder;
    padding: 5.5px 18.5px;
    letter-spacing: 0px !important;
    text-transform: capitalize;
  }
}
.current-target-board-name {
  margin-bottom: 15px;
  width: 44%;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(27, 62, 97, 0.5);
    margin-top: 15px;
    margin-bottom: 12px;
  }
}
.mui-status-field {
  width: 100%;
}
.move-ticket-dialog-title {
  background-color: rgba(242, 246, 254, 1);
  border-bottom: 1px solid rgba(27, 62, 97, 0.16);
  color: rgba(27, 62, 97, 1);
}
.move-ticket-dialog-content {
  padding: 30px 24px 30px 24px !important;
}
.move-ticket-dialog-contentText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: center;
}
.move-ticket-form-dialogContent {
  padding: 0px 24px 0px 24px !important;
}

.move-ticket-dialogActions {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding: 0px 22px 0px 22px !important;
}
.move-ticket-dialogActions-sameWorkflow {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  padding: 0px 22px 0px 22px !important;
}

@media screen and (max-width: 992px) {
  .move-ticket-dialog-title {
    padding: 8px 10px !important;
    font-size: 16px !important;
  }
  .move-ticket-dialog-content {
    padding: 10px 20px !important;
  }
  // .move-ticket-form-dialogContent {
  //   padding: 0px 20px 0px 20px !important;
  // }
  .remove-step-btn-save {
    padding: 0px 0px !important;
    .btn-content {
      font-size: 14px;
    }
  }
  .remove-step-btn-cancel {
    padding: 0px 0px !important;
    .btn-content {
      font-size: 14px;
    }
  }
  .move-ticket-dialogActions {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }
}
