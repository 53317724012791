.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: rgba(33, 43, 54, 1);
  margin-top: 15px;
  margin-bottom: 5px;
}

// .description-box{
//     width: 735px;
//     overflow-y: auto;
//     word-wrap: break-word;
// height: 160px;
// margin-top: 10px;
// padding: 12px 0px 0px 0px;
// gap: 10px;
// border-radius: 4px;
// margin-left: 5px;
// // border: 2px solid rgba(0, 0, 0, 0.185);
// border: 1px solid rgba(145, 158, 171, 0.25);

// }
.Add-desc-message {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 730px;
  line-height: 18.23px;
  margin-left: 5px;
  padding-left: 20px;
  // color: #919EAB;
}

// .Add-desc-message2{
//     font-family: DM Sans;
// font-size: 14px;
// font-weight: 400;
// line-height: 18.23px;
// margin-left: 5px;
// color: #919EAB;
// }

.activity-message {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  // margin-left: 20px;
  color: black;
  opacity: 0.85;
  // margin-top: 40px;
}

.activity-btn {
  // margin-top: 33px;
  // margin-left: 329px;
}

.activity-btn button {
  margin-left: 8px;
  // width: 105px;
  height: 36px;
}
// .add-comment{
//     margin-left: 19px;
//     margin-top: 30px;
// }

// .text-editor-box{
//     margin-left: 15px;
//     margin-top: 10px;
// }

// #editor{
//     height: 160px;
// }

// .text-editor-btn-div{
//     margin-left: 15px;
//     margin-top: 20px;
// }

// .text-editor-btn1{
//     height: 50px;
//     width: 120px;
//     background-color: #0d1461;
// }

// .text-editor-btn2{
//     margin-left: 20px;
//     height: 50px;
//     width: 120px;
//     color: black;
//     opacity: 0.5;
//     border: 1px solid rgba(13, 20, 97, 0.5)
// }

// .text-editor-btn-content{
//     font-family: DM Sans;
//     font-weight: bolder;
//     letter-spacing: 1px;
//     text-transform: capitalize;
//     font-size: large;
// }

// .btn-content{
//     font-family: DM Sans;
// font-size: 14px;
// font-weight: 700;
// line-height: 18.23px;
// letter-spacing: 0.02em;
// color: #1B3E61,
// }

.btn-active {
  font-family: DM Sans !important ;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 18.23px !important;
  letter-spacing: 0.02em !important;
  color: #1b3e61 !important;
  opacity: 0.8 !important;
  // border: 1px solid #1B3E61 0.3 !important;
  background-color: #f0f9ff !important;
  transition: none !important;
  border: 1px solid rgba(27, 62, 97, 0.4) !important;
}
.show {
  //     font-family: DM Sans;
  // font-size: 13px;
  // font-weight: 600;
  // line-height: 17.93px;
  // color: #212B36;
  // opacity: 0.8;
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.93px;
  text-align: left;
  color: rgba(33, 43, 54, 1);
  opacity: 50%;
  margin-right: 5px;
}

.sidebar {
  margin-left: 30px;
}

.attachment-section {
  margin-top: 20px;
  /* margin-bottom: 10px; */
  margin-left: 5px;
  // padding-top: 16px;
  width: 748px;
}
.attachment-box {
  padding-left: 5px;
  // height: 121px;
  width: 748px;
  display: flex;
  // padding-top: 10px;
  flex-wrap: wrap;
}
.attachment-para {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  // text-align: left;
  color: rgba(33, 43, 54, 1);
}
.right-btns {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-top: 40px;
  align-items: center;
  // width: 735px;
}
.card-title {
  font-family: DM Sans;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.04px;
  text-align: left;
  color: rgba(27, 62, 97, 1);
}
.Add-desc-message2::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.icon {
  padding-right: 10px;
}

@media screen and (max-width: 992px) {
  .description {
    margin-top: 8px;
  }
  .activity-message {
    font-size: 14px;
    // margin-top: 40px;
  }
}
