// .history-img{
//     height: 24px;
//     width: 24px;
// }

// .history-content{
//     height: 24px;
// }
.old-status {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.62px;
  text-align: left;
  color: rgba(33, 43, 54, 1);
}
.old-status-box {
  // width: 54px;
  // height: 24px;
  // margin-right: 2px;
  padding: 4px;
  border-radius: 4px;
  background: rgba(242, 244, 246, 1);
}

.arrow-img {
  margin-left: 16px;
  margin-right: 16px;
}

.new-status {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.62px;
  text-align: left;
  color: rgba(253, 116, 1, 1);
}
.mr-4 {
  margin-right: 16px;
}
.ml-4 {
  margin-left: 16px;
}
.new-status-box {
  // width: 54px;
  // height: 24px;
  // margin-right: 2px;
  padding: 4px;
  border-radius: 4px;
  background: rgba(253, 116, 1, 0.08);
}
.history-heading {
  margin-top: 10px;
}
.history-avatar {
  height: 24px !important;
  width: 24px !important;
  font-size: 10px !important;
}
.worklog-box-date-field {
  margin-left: 15px;
  span {
    text-wrap: nowrap;
  }
}
.history-container {
  width: 100% !important;
  margin-left: 0px !important;
}
