.feed-boardName {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  // text-align: left;
  color: rgba(33, 43, 54, 1);
}
.feeds-history-container {
  margin-top: 24px;
}
.worklog-content-div {
  padding-bottom: 50px;
  margin-top: 50px;
}
.comment-date {
  width: max-content;
}
.comment {
  margin-right: 10px;
}
.comment-content {
  margin-top: 5px;
  margin-bottom: 10px;
}
.feed-assignee {
  padding-bottom: 16px;
}
.feed-assignee-body {
  margin-left: 13px;
  width: 100%;
}
.feed-header {
  position: fixed;
  top: 0;
  height: 80px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
}
.feed-outer-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.feed-pagination-button {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  position: fixed;
  background-color: white;
  box-shadow: 16px;
  padding: 12px;
  left: 0;
  bottom: 0;
  right: 0;
}
.no-record-found-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .worklog-content-div {
    margin-top: 10px;
  }
}
