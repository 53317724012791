.sidebar-block {
  display: flex;
  justify-content: flex-end;
  // margin-left: -12px;
  // padding-top: 8px;
}
.add-parent {
  display: flex;
  align-items: center;
  margin-right: 7px;
}
.note {
  display: flex;
  align-items: center;
}
.note img {
  margin-right: 7px;
}
.note p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  // text-align: left;
  color: rgba(27, 62, 97, 1);
}
.sidebar-heading {
  display: flex;
  margin-bottom: 10px !important;
  width: 100%; /* Ensure full width */
  justify-content: space-between;
}
.add-parent-content {
  // width: 75px;
  margin-right: 7px;
  margin-top: 2px;
  height: 22px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: rgba(27, 62, 97, 1);
}
.sidebar-heading-content {
  display: flex;
  // width: 203px;
  justify-content: flex-end;
  width: 100%;
}
.sidebar-details {
  // height: 540px;
  width: 100%;
  // border: 1px solid red;
}
.sidebar-details-content {
  padding-bottom: 15px;
  // height: 487px;
  border: 1px solid rgba(145, 158, 171, 0.2);
  border-radius: 6px;
}
.sidebar-worklog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(242, 244, 246, 1);
  padding: 10px 15px;
}
.box {
  // margin-top: 24px;
  // border: 1px solid black;
}
.sidebar-worklog-detail {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: rgba(33, 43, 54, 1);
}
.sidebar-Addworklog-btn button {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: end;
  color: rgba(27, 62, 97, 1);
  text-decoration: underline;
  text-transform: capitalize;
  padding: 0;
  display: block;
}

.allocated-hours {
  // height: 32px;
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  // border: 1px solid red;
}

.allocated-hours p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: rgba(33, 43, 54, 1);
  // border: 1px solid blue;
}
.allocated-hours-input {
  display: flex;
  // width: 180px;
  // height: 32px;
  padding: 7px 0px 7px 10px;
  background-color: rgba(242, 244, 246, 1);
  border-radius: 4px;
  align-items: center;
}
.input-allocated {
  width: 180px !important;
  height: 32px;
  /* background-color: rgba(242, 244, 246, 1); */
  border-radius: 4px;
}
.allocated-hours-input p {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;
  color: rgba(33, 43, 54, 1);
  // text-align: left;
}

// .time-tracking{
//     height: 32px;
//     // margin-top: 20px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }
// .time-tracking p{
// font-size: 12px;
// font-weight: 500;
// line-height: 16px;
// text-align: left;
// color:rgba(33, 43, 54, 1);
// }

.time-tracking-input {
  // width: 180px;
  // height: 8px;
  // // background-color:rgba(242, 244, 246, 1);
  // border-radius: 4px;
}
.assignee-image {
  // height: 36px;
  // width: 36px;
  // border: 1px solid purple;
}
.sidebar-assigntome-btn button {
  // width: 72px;
  // height: 16px;
  // margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.62px;
  // text-align: left;
  color: rgba(27, 62, 97, 1);
  white-space: nowrap;
  text-decoration: underline;
}
.assignee {
  display: flex;
  flex-direction: column;
  // border: 1px solid green;
  // justify-content: space-between;
}
.assignee-detail {
  display: flex;
  width: 180px;
  // justify-content: start;
  align-items: center;
  // margin-left: 60px;
  // margin-right: 25pxpx;
  // border: 1px solid black;
}
// .assignee-name {
//     p{
//         width: 109px;
//         height: 18px;
//         font-family: DM Sans;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 18.23px;
//     // text-align: left;
//     color: rgba(33, 43, 54, 1);
//     }
// }

.assignee-name p {
  padding-left: 8px;
  // max-width: 109px;
  height: auto; /* Adjust height to auto to accommodate multiple lines */
  // font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  // line-height: 18.23px;
  color: rgba(33, 43, 54, 1);
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal; /* Enable normal wrapping */
}

.complete-percent {
  //     width: 81px;
  // height: 22px;
  // font-family: DM Sans;
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  opacity: 50%;
  color: rgba(33, 43, 54, 1);
}
// .allocated-hours-field{
//     height: 32px ;
// }

.reporter-detail {
  display: flex;
  // width: 60%;
  // justify-content: center;
  align-items: center;
  // margin-left: 60px;
}
.reporter-image {
  // height: 36px;
  // width: 36px;
  // margin-left: -3px;
}
.allocated-hours > p {
  width: 40%;
}
.reporter-name p {
  padding-left: 10px;
  // max-width: 109px;
  // height: 18px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  // line-height: 18.23px;
  // text-align: left;
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal; /* Enable normal wrapping */
  color: rgba(33, 43, 54, 1);
}
.created-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  // text-align: right;
  opacity: 50%;
  white-space: nowrap;
  color: rgba(33, 43, 54, 1);
  margin-top: 15px;
}
.created-date-box {
  //   margin-top: 15px;
}
.updated-date {
  margin-top: 5px;
  display: block;
  text-align: right;
  margin-bottom: 20px;
}
.due-Date-picker {
  width: 180px;
  height: 32px;
  margin-right: 10px;
  border-color: none;
}
.due-date {
  min-height: 32px;
  // width: 180px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.dueDate-box {
  height: 32px;
  width: 180px;
  border-radius: 4px;
  margin-right: 10px;
}
.due-date-input {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;
  color: rgba(33, 43, 54, 1);
  // text-align: left;
  padding-left: 10px;
}

// label-box{
//     margin-top: 28px;
// }
// .label-item{
//     height: 32px;
//     // margin-top: 20px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }
.show-none {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  text-align: left;
  color: rgba(33, 43, 54, 1);
  opacity: 70%;
}
.due-date-input-none {
  font-family: DM Sans;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18.23px !important;
  text-align: left;
  color: rgba(33, 43, 54, 1) !important;
  opacity: 70%;
  margin-left: 0px !important;
}
.due-date-input-allocate-hour-none {
  font-family: DM Sans;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18.23px !important;
  // text-align: left;
  color: rgba(33, 43, 54, 1) !important;
  opacity: 70%;
}

.taskdetail-sidebar-right-col {
  width: 60%;
}

.subtask-box {
  border: 2px solid rgba(145, 158, 171, 0.2);
  border-radius: 8px;
  margin: 0px 8px;
  box-shadow: 0px 0px 4px 0px rgba(27, 62, 97, 0.16);
}
.subtask-box-input-field {
  padding: 0px 8px;
  margin-top: 10px;
}
.assign-subtask-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px !important;
}

.assign-subtask {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px;
  text-align: end;
  color: rgb(27, 62, 97);
  text-decoration: underline;
  text-transform: capitalize;
  padding: 0;
  display: block;
  cursor: pointer;
  margin-left: 8px !important;
}
.subtask-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.62px;
  color: rgba(33, 43, 54, 1);
}

@media screen and (max-width: 992px) {
  .sidebar-worklog-detail {
    font-size: 14px;
  }
}
