@import "../../styles/css/variables";

body {
  .app-header {
    background: $header-bg-color;
    box-shadow: none;
    border-bottom: 1px solid $header-border-color;
    padding: 10px 30px;
    .logo {
      img {
        max-width: 170px;
        max-height: 30px;
      }
    }
    .user-avatar {
      width: 32px;
      height: 32px;
      // color: #1b3e61;
      // background-color: $avatar-bg-color;
      font-size: 14px;
      border: 1px solid #c7ced4;
    }
    .profile-down-arrow {
      font-size: 24px;
      fill: rgb(145 158 171 / 50%);
    }

    .header-right {
      display: flex;
      align-items: center;
      justify-content: end;
      .base-MenuButton-root {
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }
  }
  .user-name {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1b3e61 !important;
    text-transform: capitalize !important;
    flex-grow: 1;
    text-align: left !important;
    margin-left: 8px !important;
  }
  .logout-icon {
    position: relative;
    right: -7px;
    top: -15px;
    cursor: pointer;
  }
  #user-menu {
    .MuiPaper-root {
      min-width: 200px;
    }
  }
  .profile-header {
    .MuiPopover-paper {
      -webkit-filter: drop-shadow(0px 4px 50px #1b3e6140);
      filter: drop-shadow(0px 4px 50px #1b3e6140);
      border-radius: 6px;
      border: 1px solid rgb(145 158 171 / 25%);
      ul {
        padding: 0;
        li {
          padding: 8px 16px;
          // border-bottom: 1px solid rgb(145 158 171 / 25%);
          font-size: 14px;
          font-weight: 500;
          &:last-child {
            padding-bottom: 18px;
          }
          .profile-dropdown-email {
            font-size: 13px;
            color: var(--text-color, rgba(145, 158, 171, 1));
            line-height: 1;
          }
          .profile-dropdown-name {
            font-size: 14px;
            font-weight: 600;
            color: rgba(33, 43, 54, 1);
            line-height: 1;
            margin-bottom: 6px;
          }
          .profile-container {
            padding: 12px 16px;
            padding-left: 0px;
            padding-bottom: 2px;
            flex-direction: row;
          }
        }
      }
    }
  }
}
