.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .report-header-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: rgba(33, 43, 54, 1);
  }
  .date-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    .filterBy {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-right: 8px;
      color: rgba(27, 62, 97, 1);
    }
  }
}

.paddingLeft {
  padding-left: 8px;
}
.date-range-filter-date {
  padding: 10px 42px 7px 5px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px;
  color: rgba(33, 43, 54, 1) !important;
  text-transform: capitalize;
}
.report-user-name {
  font-weight: 500;
  font-size: 14px;
  color: rgba(33, 43, 54, 0.8);
}
.report-date {
  font-weight: 400;
  font-size: 14px;
  color: rgba(33, 43, 54, 1);
}
@media screen and (max-width: 992px) {
  .date-range-filter-date {
    padding: 5px 12px 3px 12px;
    font-size: 12px !important;
  }
}
