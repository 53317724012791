@import "../../styles/css/variables";
body {
  .my-task-page {
    padding: 0 !important;
    margin-top: 4px;
    a {
      color: rgba(240, 249, 255, 0.7);
      text-decoration: none;
      padding: 13px 16px;
      display: flex;
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      position: relative;
      align-items: center;
      &:hover {
        background-color: rgba(240, 249, 255, 1);
        // color: rgba(240, 249, 255, 1);
        &:before {
          content: "";
          width: 5px;
          background-color: rgb(253, 116, 1);
          height: 100%;
          position: absolute;
          left: 0;
          border-radius: 4px 0px 0px 4px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
        }
      }
      &.active {
        background-color: rgba(240, 249, 255, 1);
        div {
          border: 1px solid #1b3e61;
        }
        p {
          font-weight: 700;
          color: #1b3e61;
        }

        &:before {
          content: "";
          width: 5px;
          background-color: rgb(253, 116, 1);
          height: 100%;
          position: absolute;
          left: 0;
          border-radius: 4px 0px 0px 4px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
        }
      }
      p {
        font-family: DM Sans;
        font-size: 12px;
        font-weight: 600;
        line-height: 15.62px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #1b3e61;
        text-transform: uppercase;
        text-decoration: none;
        padding-left: 10px;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // color: #212b36cc;

        // overflow: hidden;
        // // font-size: 14px;
        // // font-weight: 700;

        // font-family: DM Sans;
        // font-size: 14px;
        // font-weight: 500;
        // line-height: 18.23px;
        // text-align: left;
      }
    }
  }
}
.avatar-container {
  display: flex;
  position: relative;
}
.checkMark-position {
  position: absolute;
  top: -2px;
  right: -3px;
  z-index: 1;
}
.milestone_mbl {
  max-height: 180px;
  overflow: auto;
  margin-top: 8px;
}
