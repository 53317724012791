.createWorkflowHeader {
  img {
    cursor: pointer;
  }
}

.header-name {
  margin-bottom: 16px;
  p:first-of-type {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: rgba(33, 43, 54, 1);
    // margin-bottom: 40px;
    // margin-top: 10px;
  }
  .create-new-button {
    font-weight: 700;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 13px;
      padding-left: 5px;
      // margin-right: 12px;
      // margin-left: 5px;
      // padding: 10px;
    }
  }
}

.table-datagrid {
  .table-header-class {
    background-color: rgba(242, 244, 246, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    height: 44px !important;
    color: rgba(27, 62, 97, 1);
  }
  .data-grid-cell {
    display: flex;
    // justify-content: center;
    align-items: center;
    min-height: 56px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: rgba(33, 43, 54, 0.8);
    text-transform: capitalize;

    // flex-wrap: wrap !important;
    // white-space: normal !important;
    // height: auto !important;
    // overflow: hidden !important;
    // .steps-container {
    //     display: flex;
    //     overflow-x: auto;
    //     white-space: nowrap;

    .workflow-steps {
      //             display: inline-block;
      //   padding: 0 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: rgba(27, 62, 97, 1);
      padding: 5.5px 8px;
      background-color: rgba(240, 249, 255, 0.5);
      border-radius: 4px;
      border: 1px solid rgba(27, 62, 97, 0.08);
      text-transform: uppercase;
    }
    .stepArrow {
      // display: inline-block;
      margin: 0px 8px;
    }
    .adminWorkflowEdit {
      cursor: pointer;
      background-color: rgba(145, 158, 171, 0.12);
      border-radius: 4px;
      padding: 6px;
      margin-right: 8px;
    }
    .adminWorkflowDelete {
      cursor: pointer;
      background-color: rgba(145, 158, 171, 0.12);
      border-radius: 4px;
      padding: 4px;
    }
    // }
  }
}

.workflowName {
  p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(145, 158, 171, 1);
    margin-bottom: 5px;
  }
}
.workflow-step-names {
  font-weight: 500;
  font-size: 14px;
  color: rgba(145, 158, 171, 1);
  margin-top: 30px;
  margin-bottom: 5px;
}
.addWorkflowSteps {
  // margin-top: 30px;
  .addWorkflowstepButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 4px;
    background-color: rgba(242, 246, 254, 0.5);
    border: 1px dashed rgba(145, 158, 171, 0.5);
    img {
      margin-right: 5px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: rgba(145, 158, 171, 1);
    }
  }
  .workflowStep-container {
    display: flex;
    margin-bottom: 10px;
    // justify-content: center;
    // align-items: center;
    .workflowStep {
      width: 85%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding: 10px 0px 10px 10px;
      border: 1px solid rgba(145, 158, 171, 0.25);
      border-radius: 4px;
    }
    .workflowStep-delete {
      background-color: rgba(145, 158, 171, 0.12);
      border-radius: 4px;
      margin-left: 10px;
      padding: 8px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.workflow-btn-save {
  // height: 42px;
  // width: 130px;
  // margin-left: -35px;
  box-shadow: "0";
  padding: 9px 39px !important;
  border-radius: 4px;
  background-color: #1b3e61 !important;
  .btn-content {
    font-weight: bolder;
    text-transform: capitalize;
  }
}
.workflow-btn-cancel {
  margin-left: 15px !important;
  // height: 42px !important;
  // width: 130px !important;

  margin-right: 20px;
  padding: 8px 39px !important;
  color: black !important;
  opacity: 0.5 !important;
  border: 1px solid rgba(13, 20, 97, 0.5) !important;
  .btn-content {
    font-weight: bolder;
    text-transform: capitalize;
  }
}

.removeStepContainer {
  // padding: 30px 0px;
  border-radius: 12px;
  .removeStepdialog {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 20px 0px;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .content-heading {
      font-weight: 700;
      font-size: 20px;
      color: rgba(27, 62, 97, 1);
      padding-bottom: 15px;
    }
    .DialogContentText {
      line-height: 22px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(33, 43, 54, 0.6);
      text-align: center;
    }
    .content-span {
      font-weight: 700;
      font-size: 14px;
      color: black;
    }
  }

  .ticket-reasign-step {
    color: rgba(27, 62, 97, 1);
    font-weight: 700;
    font-size: 16px;
  }
  .ticket-reasign-step-container {
    background-color: rgba(145, 158, 171, 0.12);
    border-radius: 4px;
    padding: 12px 16px;
    margin-top: 10px;
    display: flex;
    .initial-step {
      width: 43%;
      // padding : 15px 0px 15px 10px;
      border-radius: 4px;
      background-color: white;
      p {
        font-weight: 500;
        font-size: 14px;
        color: rgba(33, 43, 54, 1);
        line-height: 19px;
      }
    }
    img {
      padding: 0px 15px;
    }
  }

  // action-btn{
  //     display: flex !important;
  //     justify-content: center !important;
  //     align-items: center !important;
  // }
  .remove-step-btn-save {
    padding: 12px 42px;
    border-radius: 4px;
    background-color: #1b3e61 !important;
    margin-bottom: 20px;
    .btn-content {
      font-weight: 700;
      font-size: 16px;
      font-weight: bolder;
      letter-spacing: 2px;
      text-transform: capitalize;
    }
  }
  .remove-step-btn-cancel {
    padding: 12px 42px;
    margin-left: 15px !important;
    // height: 42px !important;
    // width: 130px !important;
    color: black !important;
    opacity: 0.5 !important;
    border: 1px solid rgba(13, 20, 97, 0.5) !important;
    margin-bottom: 20px;
    .btn-content {
      font-weight: 700;
      font-size: 16px;
      font-weight: bolder;
      letter-spacing: 2px;
      text-transform: capitalize;
    }
  }
}

.name-wrap {
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  height: auto !important;
  min-height: inherit !important;
  max-height: inherit !important;
  line-height: 20px !important;
  white-space: normal !important;
}

@media screen and (max-width: 992px) {
  .workflowName {
    margin-top: 18px;
  }
  .workflow-step-names {
    margin-top: 16px;
    flex-grow: 1;
  }
  .workflowStep-container {
    height: 44px;
  }
  .addWorkflowSteps {
    .addWorkflowstepButton {
      padding-top: 9px;
      padding-bottom: 9px;
      margin-bottom: 8px;
    }
  }
}
