.worklog-box {
  display: flex;
  // margin-left: 4px;
  margin-top: 50px;
  width: 100%;
}

.worklog-box-body {
  margin-left: 13px;
  width: 100%;
}
.worklog-box-body ul {
  padding-left: 20px;
}

.worklog-box-body ol {
  padding-left: 20px;
}
.worklog-box-name {
  margin-top: 3px !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18.23px !important;
  // width: 165px ;
  // text-align: left;
}
.worklog-box-date {
  margin-top: 3px !important;
  font-family: DM Sans !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18.23px !important;
  // margin-left: 350px !important;
  color: rgba(33, 43, 54, 0.8) !important;
}

.worklog-box-message {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  // text-align: left;
  margin-top: 5px;
  opacity: 0.8;
  width: 682px;
  margin-left: 5px;
  // white-space: pre-wrap; // this is for preserve spaces from begining
}

.worklog-box-btn1 {
  margin-top: 5px;
  background: none;
  border: none;
  color: none;
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.93px;
  text-align: left;
  cursor: pointer;
}

.worklog-box-btn2 {
  margin-top: 5px;
  background: none;
  border: none;
  color: none;
  margin-left: 15px;
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.93px;
  text-align: left;
  cursor: pointer;
}

.worklog-box-img {
  margin-left: 5px !important;
  margin-top: 3px;
}
.worklog-status {
  margin-top: 3px !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18.23px !important;
  margin-left: 8px !important;
  opacity: 0.8 !important;
}

.worklog-box-hours {
  margin-top: 3px !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 18.23px !important;
  margin-left: 8px !important;
}

.add-worklog-btn {
  margin-top: -36px;
  margin-left: 85px;
}
.add-worklog-btn button {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;
  text-align: center;

  // height: 36px;
  // width: 145px;
  padding: 9px 8px;
  background-color: #1b3e61;
}

.worklog-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 748px;
}
.worklog-detail {
  display: flex;
}
// .worklog-box-date-field{
//     margin-right: 13px;
// }

#editor2 {
  border: none;
  box-shadow: none;
  .ql-editor {
    ul,
    ol {
      padding-left: 0px;
      // li{
      //     padding-left: 1.0em;
      // }
    }
    padding: 0px;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    // line-height: 20px;
    // text-align: left;
    margin-top: 5px;
    opacity: 0.8;
    width: 682px;
    // margin-left: -5px;
  }

  &.ql-blank::before {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    // line-height: 18.23px;
    // margin-left: 5px;
    opacity: 0.8;
    // content: attr(data-placeholder); // Use the placeholder attribute for empty content
  }
}

// .ql-editor ul, ol {
//     padding-left: 0px;
//   }

// .Add-desc-message2{
//     font-family: DM Sans;
// font-size: 14px;
// font-weight: 400;
// line-height: 18.23px;
// margin-left: 5px;
// color: #919EAB;
// }
.ql-container.ql-disabled .ql-editor {
  user-select: text !important;
  -webkit-user-select: text !important; // For Safari
  cursor: text !important;
}

.created-date-worklog {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 50%;
  white-space: nowrap;
  color: rgb(33, 43, 54);
  // margin-top: 15px;
}

.warningicon {
  height: 15px;
  width: 15px;
}
.warningicon img {
  height: 15px;
  width: 15px;
}
.checkbox-height {
  height: 30px;
}
.checkbox-outerbox {
  margin: 8px 0px 8px 8px !important;
  min-width: 120px !important;
  max-width: 250px;
}
.select-card-dropdown{
    min-width: 120px !important;
    // max-width: 250px !important;
}
.worklog-container{
  width: 100% !important;
  margin-left: 0px !important;
}

@media screen and (max-width: 992px) {
  .add-worklog-btn {
    margin-top: 8px;
    margin-left: 0px;
  }
}
