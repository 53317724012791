.carousel-container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
}

.carousel {
  .carousel-slider {
    max-height: calc(100vh - 150px); // Adjust height as necessary
  }
  .carousel-status {
    display: none;
  }

  .carousel-slide {
    img {
      max-height: 70vh; // Adjust this value based on your needs
      object-fit: contain; // Ensures the image fits within the container
      border-radius: 5px;
    }
    .imageIsNotPresent {
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 20vh;
      }
      p {
        margin: 30px 0px;
      }
    }
  }

  // .thumbs-wrapper {
  //     padding: 10px;
  // }

  // .thumb {
  //     border: 2px solid #ddd;
  //     padding: 2px;
  //     margin-right: 10px;

  //     &.selected, &:hover {
  //         border: 2px solid #333;
  //     }
  // }
}

/* Customize the next and previous buttons */
.carousel {
  .control-prev.control-arrow,
  .control-next.control-arrow {
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Example: semi-transparent black background */
    top: 50%;
    width: 40px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    border-radius: 50%; /* Make buttons circular */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease; /* Smooth transition for background */
  }

  .control-prev.control-arrow:hover,
  .control-next.control-arrow:hover {
    background: rgba(0, 0, 0, 0.7); /* Example: darker on hover */
  }

  /* Customize the icons inside the next and previous buttons */
  .control-prev.control-arrow:before,
  .control-next.control-arrow:before {
    color: white; /* Change icon color */
    font-size: 20px; /* Adjust icon size as needed */
  }
}
