// .show-comment-body{
//     margin-left: -4px;
   
// }

// .show-comment-img{
//    height: 30px;
//    width: 30px;
//    border-radius: 50%;
//    margin-top: 22px;
//    margin-left: 20px;
// }

// // .show-comment-btn{
// //     color: black;
// // }
// .modify-comment-btn{
//     color: black;
// }
.comment-box{
    display: flex ;
    margin-left: 0px ;
    margin-top: 40px ;
    width: 100%;
}

.comment-box-body{
    width: 100%;
    margin-left: 12px;
}
.comment-box-name{
    // margin-top: 3px !important;
    font-family: DM Sans !important;
font-size: 16px !important;
font-weight: 700 !important;
line-height: 19.53px !important;
color: #212B36 !important;
// text-align: left;

}
.comment-box-date{
    // margin-top: 3px !important;
    font-family: DM Sans !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 18.23px !important;
margin-left: 15px !important;
color: #212B36 !important;
opacity: 0.7 !important;
}

.comment-box-message{
    font-family: DM Sans !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
// text-align: left;
// margin-top: 5px !important;
opacity: 0.8 !important;
width: 695px;
// width: 750px !important;
}

.comment-box-btn1{
    // margin-top: 5px !important;
    background: none !important;
    border: none !important;
    color: none !important;
    font-family: DM Sans !important;
font-size: 13px !important;
font-weight: 500 !important;
line-height: 16.93px !important;
text-align: left !important;
cursor: pointer !important;
}

.comment-box-btn2{
    // margin-top: 5px;
    background: none;
    border: none;
    color: none;
    margin-left: 15px;
    font-family: DM Sans;
font-size: 13px;
font-weight: 500;
line-height: 16.93px;
text-align: left;
cursor: pointer;
}

.comment-box-img{
   
    margin-left: 5px !important;
    margin-top: 3px;
}
// .show{
//     font-family: DM Sans;
// font-size: 13px;
// font-weight: 600;
// line-height: 17.93px;
// color: #212B36;
// opacity: 0.8;
// }

.comment-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 748px;
}
.comment-container{
    width: 100% !important;
    margin-left: 0px !important;
}