.selected-assignee {
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
}
.dataGridColumn {
  display: flex;
  justify-content: center;
}

.invalid-cell {
    background-color: #fdd;
    color: #a00;
    padding-top: 10px;
    padding-bottom: 10px;
  }
.description-cell{
    // overflow: auto; /* Allow scrolling */
    // max-height: 100%; /* Set a maximum height */
    // overflow: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: pre-wrap;
}
.allocatedHours{
    display: flex;
    justify-content: center;
    width: 100%;
}
.importCard-title {
  background-color: rgba(242, 246, 254, 1);
  border-bottom: 1px solid rgba(27, 62, 97, 0.16);
  color: rgba(27, 62, 97, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-assignee {
  padding-top: 10px;
  margin-right: 15px;
}

@media screen and (max-width: 992px) {
  .importCard-title {
    font-size: 16px !important;
    padding: 8px 10px !important;
  }
  .select-assignee {
    font-size: 14px;
  }
}
