// _variables.scss
$body-background-color: #f2f6fe;
$header-bg-color: #ffffff;
$header-border-color: rgba(145, 158, 171, 0.25);
$avatar-bg-color: #dce6ec;
$sidebar-bg-color: #ffffff;
$primary-button-bg-color: #fd7401;
$color-white: #ffffff;
$color-black: #000000;
$white-button-bg: #ffffff;
$white-button-color: #212b36;
$white-button-border-color: rgba(145, 158, 171, 0.2);
$blue-text-color: rgba(27, 62, 97, 1);
$theme-color: rgba(27, 62, 97, 1);

// Values
$button-border-radius: 5px;
